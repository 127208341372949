import type { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { captureException } from '@sentry/capacitor';
import { type ReactNode, useLayoutEffect, useState } from 'react';

import { msalInstance } from './utils';

interface Props {
  children: ReactNode;
}

const IdentityProvider = ({ children }: Props) => {
  const [instance, setInstance] = useState<PublicClientApplication | null>(null);

  // Correctly deal with async behaviour of pcaInstance
  // Also setup react router integration
  useLayoutEffect(() => {
    msalInstance()?.then(setInstance).catch(captureException);
  }, []);

  if (!instance) return null;

  return <MsalProvider instance={instance}>{children}</MsalProvider>;
};

export default IdentityProvider;
