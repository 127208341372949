import { alpha, type Components, type Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 4,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
      marginTop: theme.spacing(1),
    }),
    list: ({ theme }) => ({
      'li:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    }),
  },
};

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '16px',
      lineHeight: '24px',
      weight: 500,
      transition: theme.transitions.create(['color', 'background-color']),
      [theme.breakpoints.up('sm')]: {
        ':hover': {
          backgroundColor: alpha(theme.palette.brand.main.invert, 0.2),
          color: theme.palette.neutral01[100],
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.brand.main[100], 0.1),
        color: theme.palette.brand.main[100],
      },
    }),
  },
};
