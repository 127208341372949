import { alpha, type SxProps, type Theme } from '@mui/material';
import type { OverridesStyleRules } from '@mui/material/styles/overrides';
import type { SystemCssProperties } from '@mui/system';

export const lineClamp = (
  lineClamp: SystemCssProperties<Theme>['WebkitLineClamp'],
): SxProps<Theme> => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lineClamp,
  overflow: 'hidden',
});

export const singleLineTruncate = (): SxProps<Theme> => ({
  whiteSpace: 'nowrap', // Ensures the text stays on a single line
  overflow: 'hidden', // Ensures overflow text is hidden
  textOverflow: 'ellipsis', // Adds ellipsis to overflow text
});

export const hideScrollbar: Partial<OverridesStyleRules> = {
  '::-webkit-scrollbar': { display: 'none' },
  scrollbarWidth: 'none',
};

export const hideClearIcon = (theme: Theme) => ({
  'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration':
    {
      display: 'none',
    },
  '.clear-icon': {
    transition: theme.transitions.create('opacity'),
  },
  '& .Mui-focused': {
    '.clear-icon': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  ':hover': {
    '.clear-icon': {
      visibility: 'visible',
      opacity: 1,
    },
  },
});

// Css trick to make gradient animation
export const gradientAnimation = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  transition: 'opacity 0.2s ease-in-out',
  opacity: 0,
};

export const highlightGradient = (color: string, opacity = 0.1) =>
  `linear-gradient(180deg, ${alpha(color, opacity)} 0%, ${alpha(color, opacity)} 100%)`;

export const cardHighlightColorMix = (
  theme: Theme,
  percentageInvert = '10%',
  percentageHighlight = '90%',
) =>
  `color-mix(in srgb, ${theme.palette.brand.articleCard.highlighted.invert} ${percentageInvert}, ${theme.palette.brand.articleCard.highlighted[100]} ${percentageHighlight})`;

export const cardSurveyColorMix = (
  theme: Theme,
  percentageInvert = '10%',
  percentageHighlight = '90%',
) =>
  `color-mix(in srgb, ${theme.palette.brand.surveyCard.overview.invert} ${percentageInvert}, ${theme.palette.brand.surveyCard.overview[100]} ${percentageHighlight})`;
