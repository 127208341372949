import { captureException, setTag } from '@sentry/capacitor';
import { type ReactNode, useEffect } from 'react';

import { isWeb } from 'utils/capacitor.utils';

import { useTenantConfig } from 'queries/tenants/useTenantConfig';

interface Props {
  children: ReactNode;
}

const TenantGate = ({ children }: Props) => {
  const { config, isLoading, error } = useTenantConfig();

  useEffect(() => {
    if (error) {
      console.error(error);
      captureException(error);
      if (isWeb) {
        window.location.href = 'https://spencer.co';
      }
    }
  }, [error]);

  // Set Sentry user so we can track errors per user
  useEffect(() => {
    if (!config?.id) return;
    setTag('customer', config.id);
  }, [config?.id]);

  if (isLoading || !!error) return null;

  return children;
};

export default TenantGate;
