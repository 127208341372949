// Since nothing is loaded at this point the fallback can only return basic HTML & CSS

import { isDev, isStag } from 'utils/env.utils';

import { useLoadingScreen } from 'hooks/useLoadingScreen';
import { localStoragePersister, queryClient } from 'services/react-query';

interface FallbackProps {
  error: unknown;
  componentStack?: string | null;
  eventId?: string | null;
  resetError?(): void;
}

// Do not attempt to translate this error or add Mui styles, they are not loaded yet!
const RootFallback = ({ error, eventId, componentStack }: FallbackProps) => {
  useLoadingScreen({ spinner: false, splashScreen: false });
  if (isDev || isStag) {
    const message = error instanceof Error ? error.message : 'Unknown error';
    console.error(message, componentStack);
  }

  const handleRefresh = () => {
    try {
      queryClient.clear();
      localStoragePersister.removeClient();
      sessionStorage.clear();
      localStorage.clear();
    } catch {
      // dont care
    }
    window.location.href = '/';
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
            body {
              margin: 0;
              padding: 0;
            }
            #root {
              margin: 0;
              padding: 0;
              width: 100vw;
              height: 100vh;
              display: flex;
              flex-direction: column;
            }
            button:hover { filter: brightness(1.1) }
            button:active { filter: brightness(1.2) }
          `,
        }}
      />
      <div
        style={{
          maxWidth: '1152px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          fontFamily: 'sans-serif',
          backgroundColor: '#F2F7FA',
          padding: '80px 24px',
          borderRadius: 16,
          margin: 16,
          alignSelf: 'center',
          gap: '8px',
          flex: 1,
          overflow: 'hidden',
          // margin: '0 auto',
        }}
      >
        {/* refresh icon, can't use Mui here because we're outside of the Provider's scope */}
        <svg fill="#EE2E5D" viewBox="0 0 24 24" width={40} height={40}>
          <path
            fill="#EE2E5D"
            fillRule="evenodd"
            d="M1 12 12 1l11 11-11 11L1 12Zm10-6h2v8h-2V6Zm2 9h-2v2h2v-2Z"
            clipRule="evenodd"
          />
        </svg>

        <h2 style={{ fontSize: '31.25px', margin: 0 }}>Something went wrong</h2>
        <p style={{ fontSize: '16px', margin: 8 }}>Try again later.</p>
        <small style={{ fontSize: '12px', margin: 8 }}>Event Id: {eventId}</small>
        <button
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            boxSizing: 'border-box',
            outline: 0,
            margin: 0,
            cursor: 'pointer',
            userSelect: 'none',
            verticalAlign: 'middle',
            WebkitAppearance: 'none',
            textDecoration: 'none',
            minWidth: 64,
            padding: '12px 10px',
            border: 0,
            textTransform: 'none',
            color: '#FFFFFF',
            borderRadius: '12px',
            lineHeight: '24px',
            fontSize: '18px',
            fontWeight: 600,
            backgroundColor: '#0090FF',
          }}
          onClick={handleRefresh}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default RootFallback;
