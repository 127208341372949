import type { AuthError } from '@azure/msal-browser';
import { Alert, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { identityMismatchError } from './utils';

interface Props {
  error: AuthError;
}

const ErrorAlert = ({ error }: Props) => {
  const intl = useIntl();
  const emailMismatch = identityMismatchError(error);
  const translations = emailMismatch
    ? {
        title: intl.formatMessage({ id: 'core_identity_error_authentication_mismatch' }),
        description: intl.formatMessage(
          { id: 'core_identity_error_authentication_mismatch_description' },
          { email: emailMismatch },
        ),
      }
    : {
        title: intl.formatMessage({ id: 'core_general_alert_error_body_login_authflow_fail' }),
        description: null,
      };

  return (
    <Alert variant="filled" severity="error" sx={{ my: 4 }}>
      <Typography variant="body1">{translations.title}</Typography>
      {translations.description && (
        <Typography variant="body2">{translations.description}</Typography>
      )}
    </Alert>
  );
};

export default ErrorAlert;
