import { setExtra } from '@sentry/capacitor';
import { type ReactNode, useEffect, useMemo } from 'react';
import { createIntl, createIntlCache, RawIntlProvider as ReactRawIntlProvider } from 'react-intl';

import { useInitializeLocales } from 'queries/tenants/useInitializeLocales';
import { useLocales } from 'queries/tenants/useLocales';
import { useSelectedLocale } from 'services/i18n';
import { useSpencerStore } from 'store/spencer';

interface Props {
  children: ReactNode;
}

const intlCache = createIntlCache();

// Needs to be before IdentityGate since we have a IdentityRetry.tsx component that needs translations
const IntlProvider = ({ children }: Props) => {
  const { locale } = useSelectedLocale();
  const { setLocale } = useSpencerStore((state) => state.actions);
  const { locales, defaultLocale } = useLocales();
  const { messages, isLoading, isError: intlError } = useInitializeLocales();

  const intl = useMemo(() => {
    if (messages && locale in messages) {
      return createIntl(messages[locale], intlCache);
    }
  }, [locale, messages]);

  useEffect(() => {
    // Only update the locale if we know it doesn't match
    if (!locales.includes(locale) && locales.length && defaultLocale && locale) {
      setLocale(defaultLocale);
    }
  }, [defaultLocale, locale, locales, setLocale]);

  // Set Sentry locale so we know which locale the user is using
  useEffect(() => {
    setExtra('selectedLocale', locale);
  }, [locale]);

  if (intlError) {
    throw new Error('Something went wrong while loading the translations');
  }

  if (isLoading || !intl) return null;

  return <ReactRawIntlProvider value={intl}>{children}</ReactRawIntlProvider>;
};

export default IntlProvider;
