import type { IconNames } from 'sprite/types';

import { Routes } from 'constants/routes.constants';
import type { Href } from 'types/api.types';
import { type IframeModules, Modules } from 'types/module.types';

interface WebModuleConfig {
  moduleIds: Array<Modules>;
  label: string;
  to: string;
  startIcon?: IconNames;
  startImage?: Href;
  showInMobileMenu?: boolean;
}

export const webModules: Array<WebModuleConfig> = [
  {
    moduleIds: [Modules.CompanyNews],
    label: 'module_companynews_menu_item_overview',
    to: Routes.News,
    startIcon: 'IcoNews',
    showInMobileMenu: false,
  },
  {
    moduleIds: [Modules.Tasks],
    label: 'module_delhaizemyday_menu_item_overview',
    to: Routes.Tasks,
    showInMobileMenu: true,
    startIcon: 'IcoTasks',
  },
  {
    moduleIds: [Modules.People, Modules.Sites],
    label: 'core_menu_item_organisation',
    to: Routes.Organisation,
    startIcon: 'IcoOrganigram',
    showInMobileMenu: true,
  },
  {
    moduleIds: [Modules.DocumentLibrary],
    label: 'module_documentlibrary_overview_title',
    to: Routes.Documents,
    startIcon: 'IcoDocuments',
    showInMobileMenu: true,
  },
  {
    moduleIds: [Modules.LinkLibrary],
    label: 'module_linklibrary_menu_item_overview',
    to: Routes.Links,
    startIcon: 'IcoLinks',
    showInMobileMenu: true,
  },
  {
    moduleIds: [Modules.Forms],
    label: 'module_forms_display_name',
    to: Routes.Forms,
    startIcon: 'IcoForm',
    showInMobileMenu: true,
  },
];

interface IframeModuleConfig {
  moduleId: IframeModules;
  label: string;
  apiEndpoint: string;
  to: Routes;
  startIcon: IconNames;
  errorMessage?: string;
  iframeProps?: Omit<React.IframeHTMLAttributes<HTMLIFrameElement>, 'src'>;
}

export const iframeModules: Array<IframeModuleConfig> = [
  {
    moduleId: Modules.Pixieset,
    label: 'module_photogallery_menu_item_overview',
    apiEndpoint: '/pixieset/iframe',
    to: Routes.Pixieset,
    startIcon: 'IcoImage',
  },
  {
    moduleId: Modules.Events,
    label: 'module_events_menu_item_overview',
    apiEndpoint: '/events/iframe',
    to: Routes.Events,
    startIcon: 'IcoCalendar',
  },
  {
    moduleId: Modules.Doccle,
    label: 'module_doccle_menu_item_overview',
    apiEndpoint: '/doccle/iframe',
    to: Routes.Doccle,
    startIcon: 'IcoDoccle',
    iframeProps: {
      sandbox:
        'allow-popups allow-same-origin allow-scripts allow-top-navigation allow-forms allow-popups-to-escape-sandbox allow-downloads',
      title: 'test',
      id: 'doccle-receiver-plugin',
    },
  },
  // {
  //   moduleId: Modules.ChatLayer,
  //   label: 'module_chat_layer_menu_item_overview',
  //   apiEndpoint: '/chat-layer/iframe',
  //   to: '/campfire',
  //   startIcon: 'IcoCampfire',
  //   iframeProps: {
  //     title: 'campfire',
  //     sandbox: 'allow-scripts allow-same-origin',
  //   },
  // },
  {
    moduleId: Modules.Epromote,
    label: 'module_epromote_menu_item_overview',
    apiEndpoint: '/e-promote/iframe',
    to: Routes.Epromote,
    startIcon: 'IcoEpromote',
  },
  {
    moduleId: Modules.Coins,
    startIcon: 'IcoCoins',
    label: 'module_coins_overview_title',
    to: Routes.Coins,
    apiEndpoint: '/coins/iframe',
  },
  {
    moduleId: Modules.Dialoog,
    startIcon: 'IcoContinueDialog',
    label: 'module_dialoog_overview_title',
    to: Routes.Dialoog,
    apiEndpoint: '/dialoog/iframe',
  },
  {
    moduleId: Modules.Sabacloud,
    startIcon: 'IcoEducation',
    label: 'module_sabacloud_overview_title',
    to: Routes.Sabacloud,
    apiEndpoint: '/sabacloud/iframe',
  },
  {
    moduleId: Modules.BeaulieuBighome,
    startIcon: 'IcoBeaulieuBighome',
    label: 'module_beaulieu_bighome_overview_title',
    to: Routes.BeaulieuBighome,
    apiEndpoint: '/bighome/iframe',
  },
  {
    moduleId: Modules.BelgoCatering,
    startIcon: 'IcoLunch',
    label: 'module_belgocatering_overview_title',
    to: Routes.BelgoCatering,
    apiEndpoint: '/belgocatering/iframe',
  },
  {
    moduleId: Modules.LunchDeloitte,
    startIcon: 'IcoLunchMenu',
    label: 'module_lunch_deloitte_overview_title',
    to: Routes.LunchDeloitte,
    apiEndpoint: '/lunchmenu/iframe',
  },
  {
    moduleId: Modules.Cardstoconnect,
    startIcon: 'IcoDuplicate',
    label: 'module_cardstoconnect_overview_title',
    to: Routes.Cardstoconnect,
    apiEndpoint: '/cardstoconnect/iframe',
  },
  {
    moduleId: Modules.BeaulieuSportswear,
    startIcon: 'IcoShirt',
    label: 'module_beaulieu_sportswear_overview_title',
    to: Routes.BeaulieuSportswear,
    apiEndpoint: '/sportswear/iframe',
  },
  {
    moduleId: Modules.BeaulieuStepchallenge,
    startIcon: 'IcoShoeRun',
    label: 'module_beaulieu_stepchallenge_overview_title',
    to: Routes.BeaulieuStepchallenge,
    apiEndpoint: '/stepchallenge/iframe',
  },
  {
    moduleId: Modules.SelfserviceFaq,
    startIcon: 'IcoDocumentHelp',
    label: 'module_selfservice_faq_overview_title',
    to: Routes.SelfserviceFaq,
    apiEndpoint: '/yource-selfservice/iframe',
  },
  {
    moduleId: Modules.IframeDemoOne,
    startIcon: 'IcoWorkelo',
    label: 'module_iframe_demo_one_overview_title',
    to: Routes.IframeDemoOne,
    apiEndpoint: '/iframe_demo_one/iframe',
    errorMessage: 'module_iframe_demo_one_overview_error_message',
  },
  {
    moduleId: Modules.IframeDemoTwo,
    startIcon: 'IcoImage',
    label: 'module_iframe_demo_two_overview_title',
    to: Routes.IframeDemoTwo,
    apiEndpoint: '/iframe_demo_two/iframe',
  },
  {
    moduleId: Modules.IframeDemoThree,
    startIcon: 'IcoEducation',
    label: 'module_iframe_demo_three_overview_title',
    to: Routes.IframeDemoThree,
    apiEndpoint: '/iframe_demo_three/iframe',
  },
  {
    moduleId: Modules.SocialWallOne,
    startIcon: 'IcoImage',
    label: 'module_social_wall_one_overview_title',
    to: Routes.SocialWallOne,
    apiEndpoint: '/module_social_wall_one/iframe',
  },
  {
    moduleId: Modules.SocialWallTwo,
    startIcon: 'IcoImage',
    label: 'module_social_wall_two_overview_title',
    to: Routes.SocialWallTwo,
    apiEndpoint: '/module_social_wall_two/iframe',
  },
  {
    moduleId: Modules.WorkeloOne,
    startIcon: 'IcoWorkelo',
    label: 'module_workelo_overview_title',
    to: Routes.WorkeloOne,
    apiEndpoint: '/module_workelo_one/iframe',
    errorMessage: 'module_workelo_overview_error_message',
  },
  {
    moduleId: Modules.WorkeloTwo,
    startIcon: 'IcoWorkelo',
    label: 'module_workelo_overview_title',
    to: Routes.WorkeloTwo,
    apiEndpoint: '/module_workelo_two/iframe',
    errorMessage: 'module_workelo_overview_error_message',
  },
  {
    moduleId: Modules.TallyForm,
    startIcon: 'IcoSelecta',
    label: 'module_tally_overview_title',
    to: Routes.TallyForm,
    apiEndpoint: '/module_tally_form/iframe',
  },
];
