import { useQuery } from '@tanstack/react-query';

import { workeloMagicLinkQuery } from './queries';

export const useWorkeloMagicLink = (config: Partial<typeof workeloMagicLinkQuery> = {}) => {
  const query = useQuery({
    ...workeloMagicLinkQuery,
    // meta: { store: true },
    ...config,
  });

  return {
    data: query.data,
  };
};
