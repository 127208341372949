import { useQuery } from '@tanstack/react-query';

import { currentVersionQuery } from './queries';

export const useAppVersion = () => {
  const query = useQuery({ ...currentVersionQuery });

  return {
    nativeVersion: query.data?.native,
    bundleVersion: query.data?.bundle?.version,
    isLoading: query.isLoading,
  };
};
