import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import {
  defaultGetNextPageParam,
  defaultInitialPageParam,
  resolveHrefsQueries,
} from 'queries/utils';
import { apiClient, cleanParams } from 'services/api/client';
import { queryClient } from 'services/react-query';
import type { Collection, Href, Pagination } from 'types/api.types';
import type { User } from 'types/user.types';

const PREFIX_KEY = 'users';

// Since a user can be queried using their serviceUuid or spencerUuid
// Ensure we set the cache entry for both items
export const ensureQueryDataUserDetails = async (hrefs: Collection<Array<Href>>) =>
  resolveHrefsQueries(hrefs, ({ id }) =>
    queryClient.ensureQueryData({
      queryKey: userQuery(id ?? '').queryKey,
      queryFn: async () => {
        const response = await apiClient.get(`api/users/${id}`).json<User>();
        queryClient.setQueryData([PREFIX_KEY, response.serviceUuid], response);
        return response;
      },
      staleTime: Duration.TEN_MIN,
    }),
  );

export const infiniteUsersQuery = () =>
  infiniteQueryOptions({
    queryKey: [PREFIX_KEY, 'infinite'],
    initialPageParam: defaultInitialPageParam,
    queryFn: async ({ pageParam }) => {
      const response = await apiClient
        .get('api/users', {
          searchParams: cleanParams({
            limit: 10,
            p: Number(pageParam),
          }),
        })
        .json<Collection<Array<Href>>>();

      return ensureQueryDataUserDetails(response);
    },
    getNextPageParam: defaultGetNextPageParam,
    staleTime: Duration.TEN_MIN,
  });

export const usersQuery = (params: Pagination) =>
  queryOptions({
    queryKey: [PREFIX_KEY, cleanParams(params)],
    queryFn: async () => {
      const response = await apiClient
        .get('api/users', {
          searchParams: cleanParams(params),
        })
        .json<Collection<Array<Href>>>();

      return ensureQueryDataUserDetails(response);
    },
    staleTime: Duration.TEN_MIN,
  });

export const userQuery = (userId: User['spencerUuid']) =>
  queryOptions({
    queryKey: [PREFIX_KEY, userId],
    queryFn: async () => {
      const response = await apiClient.get(`api/users/${userId}`).json<User>();
      queryClient.setQueryData([PREFIX_KEY, response.serviceUuid], response);
      return response;
    },
    staleTime: Duration.TEN_MIN,
  });

export const userByServiceIdQuery = (serviceId: User['serviceUuid']) =>
  queryOptions({
    queryKey: [PREFIX_KEY, serviceId],
    queryFn: async () => {
      const response = await apiClient.get(`api/users/service-uuid/${serviceId}`).json<User>();
      queryClient.setQueryData([PREFIX_KEY, response.spencerUuid], response);
      return response;
    },
    staleTime: Duration.TEN_MIN,
  });

export const infiniteColleaguesQuery = (userId: User['spencerUuid']) =>
  infiniteQueryOptions({
    queryKey: [PREFIX_KEY, 'infinite', 'colleagues', userId],
    initialPageParam: defaultInitialPageParam,
    queryFn: async ({ pageParam }) => {
      const response = await apiClient
        .get(`api/users/${userId}/colleagues`, {
          searchParams: cleanParams({
            limit: 10,
            p: Number(pageParam),
          }),
        })
        .json<Collection<Array<Href>>>();

      return ensureQueryDataUserDetails(response);
    },

    getNextPageParam: defaultGetNextPageParam,
    staleTime: Duration.FIVE_MIN,
  });

export const infiniteTeamsQuery = (userId: User['spencerUuid']) =>
  infiniteQueryOptions({
    queryKey: [PREFIX_KEY, 'infinite', 'team', userId],
    initialPageParam: defaultInitialPageParam,
    queryFn: async ({ pageParam }) => {
      const response = await apiClient
        .get(`api/users/${userId}/team`, {
          searchParams: cleanParams({
            limit: 10,
            p: Number(pageParam),
          }),
        })
        .json<Collection<Array<Href>>>();

      return ensureQueryDataUserDetails(response);
    },
    getNextPageParam: defaultGetNextPageParam,
    staleTime: Duration.FIVE_MIN,
  });
