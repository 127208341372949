import { alpha, type Components, type Theme } from '@mui/material';

export const MuiTablePagination: Components<Theme>['MuiTablePagination'] = {
  styleOverrides: {
    menuItem: ({ theme }) => ({
      ':hover': {
        backgroundColor: alpha(theme.palette.brand.main[100], 0.1),
        color: theme.palette.brand.main[100],
      },
    }),
  },
};
