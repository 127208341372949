import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';

import RootFallback from './RootFallback';

interface Props {
  children: ReactNode;
}

// This is the everything else failed fallback and is normally never shown to the user
// If there is an issue with the connectors then this will make sure we see it in sentry
const RootBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'root')}
    fallback={(props) => <RootFallback {...props} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RootBoundary;
