import { useMutation } from '@tanstack/react-query';

import { msalLogout } from 'services/identity/utils';

export const useLogOut = () => {
  const mutation = useMutation({
    mutationFn: msalLogout,
  });

  return {
    logOut: mutation.mutateAsync,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};
