import { useAccount } from '@azure/msal-react';

import { useUser } from './useUser';

export const useCurrentUser = () => {
  const account = useAccount();
  const userId = account?.idTokenClaims?.spencer_id?.toString();
  const query = useUser(userId ?? '', {
    enabled: !!userId,
    // meta: { store: true },
  });

  return {
    user: query.data,
    userId: query.data?.spencerUuid ?? '',
    serviceId: query.data?.serviceUuid ?? '',
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};
