type SharedPromiseKeys = 'access-token' | 'msal-in-progress' | 'service-worker';

// sets up a shared promise cache so we can make multiple calls to
// promise based functions which all will return the same promise based on the key
// important is to keep the key unique
function createSharedPromisesCache() {
  const promises: Map<SharedPromiseKeys, Promise<unknown>> = new Map();

  return function sharedPromise<T>(key: SharedPromiseKeys, fn: () => Promise<T>): Promise<T> {
    if (!promises.has(key)) {
      const promise = fn().finally(() => {
        promises.delete(key);
      });
      promises.set(key, promise);
    }
    return promises.get(key) as Promise<T>;
  };
}

export const sharedPromisesCache = createSharedPromisesCache();
