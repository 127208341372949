import { SymbolDispose } from 'services/react-query/disposeOnCacheEvict';
import type { Collection, Href } from 'types/api.types';

const filteredSettledItems = <T>(data: Array<PromiseSettledResult<Awaited<T>>>) =>
  data.reduce<Array<T>>((memo, item) => {
    if (item.status === 'fulfilled') {
      memo.push(item.value);
    }
    return memo;
  }, []) ?? [];

export const resolveHrefsQueries = async <T, D extends Href>(
  hrefs: Collection<Array<D>>,
  resolver: (params: { id: string | undefined; data: D }) => Promise<T>,
): Promise<Collection<Array<T>>> => {
  const data = await Promise.allSettled<T>(
    // Don't use resolveHrefIds as some endpoints are based on uuid
    hrefs.data?.map((href) => resolver({ id: resolveHrefUuid(href), data: href })) ?? [],
  );

  return {
    data: filteredSettledItems(data),
    meta: hrefs.meta,
    links: hrefs.links,
  };
};

export const resolveHrefUuid = (data: Href | undefined) =>
  data && data.href ? (data.href.split('/').pop() ?? '') : undefined;

export const createDisposingObjectUrl = (blob: Blob) => {
  const url = URL.createObjectURL(blob);
  return {
    url,
    [SymbolDispose]: () => URL.revokeObjectURL(url),
  };
};

export const defaultInitialPageParam = '1';

export const defaultGetNextPageParam = (lastPage: Collection<Array<unknown>>) => {
  const next = lastPage?.links?.next;
  if (!next) return undefined;
  const nextPage = new URL(next).searchParams.get('p');
  return nextPage ?? undefined;
};
