import { alpha, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useTenantConfig } from 'queries/tenants/useTenantConfig';

import OnboardingStep from 'components/@onboarding/OnboardingStep';
import { type OnboardingProps, OnboardingSteps } from 'components/@onboarding/types';

const Privacy = ({ skipLanguageStep, setActiveStep }: OnboardingProps) => {
  const { config } = useTenantConfig();

  const handleAccept = () => {
    setActiveStep(OnboardingSteps.selectTopics);
  };

  return (
    <OnboardingStep
      title={<FormattedMessage id="core_intake_privacy_read" />}
      content={
        <Typography
          variant="body2"
          sx={(theme) => ({
            '& a': { color: theme.palette.brand.action.main[100], fontWeight: 800 },
            '& a:hover': { color: alpha(theme.palette.brand.action.main[100], 0.9) },
            '& a:active': { color: alpha(theme.palette.brand.action.main[100], 0.8) },
          })}
        >
          <FormattedMessage
            id="module_web_onboarding_privacy"
            values={{
              name: config?.name,
              a: (chunks) => (
                <a target="_blank" href={config?.links?.privacy} rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      }
      nextButtonText={<FormattedMessage id="core_intake_privacy_accept_and_login" />}
      onNext={handleAccept}
      onBack={skipLanguageStep ? undefined : () => setActiveStep(OnboardingSteps.selectLanguage)}
    />
  );
};

export default Privacy;
