export enum Routes {
  NotFound = '/not-found',
  Root = '/',

  // General
  Profile = '/profile/:serviceId',
  Settings = '/settings',
  Chat = '/chat',
  Menu = '/menu',

  // Search
  Search = '/search',

  // News
  News = '/news',
  NewsDetail = '/news/:itemId',
  NewsDetailPreview = '/news/:itemId/preview',

  // Forms
  Forms = '/forms',
  FormsDetail = '/forms/:itemId',

  // Organisation
  Organisation = '/organisation',
  People = '/organisation/people',
  Sites = '/organisation/sites',

  // Sites
  SitesDetail = '/sites/:siteId',

  // Links
  Links = '/links',
  IframeDetail = '/iframes/:id',

  // Bookmarks
  Bookmarks = '/bookmarks',

  // Surveys
  SurveyDetail = '/surveys/:surveyId',
  SurveyEdit = '/surveys/:surveyId/edit',

  // Documents
  Documents = '/documents',
  DocumentsFolder = '/documents/folder/:folderId',
  DocumentsDetail = '/documents/item/:documentId',
  DocumentsFavourites = '/documents/favourites',

  // Tasks
  Tasks = '/tasks',
  TasksNew = '/tasks/new',
  TasksSearch = '/tasks/search',
  TasksFilter = '/tasks/:filter',
  TasksCategory = '/tasks/categories/:categoryId',
  TasksDetails = '/tasks/details/:taskId',

  // Iframe modules
  Pixieset = '/pixieset',
  Events = '/events',
  Doccle = '/doccle',
  Epromote = '/epromote',
  Coins = '/coins',
  Dialoog = '/dialoog',
  Sabacloud = '/sabacloud',
  BeaulieuBighome = '/bighome',
  BelgoCatering = '/belgocatering',
  LunchDeloitte = '/lunchmenu',
  Cardstoconnect = '/cardstoconnect',
  BeaulieuSportswear = '/sportswear',
  BeaulieuStepchallenge = '/stepchallenge',
  SelfserviceFaq = '/yource-selfservice',
  IframeDemoOne = '/module_iframe_demo_one',
  IframeDemoTwo = '/module_iframe_demo_two',
  IframeDemoThree = '/module_iframe_demo_three',
  SocialWallOne = '/module_social_wall_one',
  SocialWallTwo = '/module_social_wall_two',
  WorkeloOne = '/module_workelo_one',
  WorkeloTwo = '/module_workelo_two',
  TallyForm = '/module_tally_form',
}
