import type { DocumentItem } from './documents.types';
import type { Link } from './links.types';
import type { NewsItemType } from './news.types';
import type { Site } from './sites.types';
import type { User } from './user.types';

export enum SpencerModel {
  USER = 'spencer_user.model.user',
  NEWS = 'spencer_news.entity.item',
  LINKS = 'spencer_link_library.model.link',
  SITES = 'spencer_facility.entity.site',
  DOCUMENTS = 'spencer_document_library.entity.node',
}

export enum SearchType {
  News = 'news',
  People = 'people',
  Links = 'links',
  Document = 'documents',
  Sites = 'sites',
}

export const SpencerModelLookup = {
  [SpencerModel.NEWS]: SearchType.News,
  [SpencerModel.USER]: SearchType.People,
  [SpencerModel.LINKS]: SearchType.Links,
  [SpencerModel.DOCUMENTS]: SearchType.Document,
  [SpencerModel.SITES]: SearchType.Sites,
};

type SearchOrder = 'asc' | 'desc';
type DocumentSortField = 'timestamp' | 'name';

export type SearchParams = {
  query: string;
  p?: number;
  'spencer-model'?: SpencerModel;
  order?: SearchOrder;
  sort?: DocumentSortField;
  limit?: number;
};

export type SearchResult = {
  score: number;
  spencerModel: SpencerModel;
  href: string;
  hits: Array<{ key: string; values: Array<string> }>;
};

type SearchModel =
  | 'spencer_user.model.user'
  | 'spencer_directory.model.resource'
  | 'spencer_news.entity.item'
  | 'spencer_link_library.model.link'
  | 'spencer_facility.entity.site'
  | 'spencer_document_library.entity.node';

type SearchBaseResult<SpencerModel extends SearchModel, TObject> = {
  score: number;
  href: string;
  spencerModel: SpencerModel;
  object: TObject;
  hits: Array<{ key: string; values: Array<string> }>;
};

type SearchNewsItem = Pick<
  NewsItemType,
  | 'id'
  | 'content'
  | 'publishedAt'
  | 'title'
  | 'topics'
  | 'commentFeature'
  | 'featured'
  | 'highlight'
  | 'featureMedia'
  | 'featureMediaSquare'
>;

type SearchNewsItemResult = SearchBaseResult<'spencer_news.entity.item', SearchNewsItem>;

type SearchLinkResult = SearchBaseResult<'spencer_link_library.model.link', Link>;

type SearchUserResult = SearchBaseResult<'spencer_user.model.user', User>;

type SearchSiteResult = SearchBaseResult<'spencer_facility.entity.site', Site>;

type SearchDocumentResult = SearchBaseResult<'spencer_document_library.entity.node', DocumentItem>;

export type SearchResultTypes =
  | SearchNewsItemResult
  | SearchLinkResult
  | SearchUserResult
  | SearchSiteResult
  | SearchDocumentResult;
