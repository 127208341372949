// Everything implemented in the web app is defined here
enum WebModules {
  CompanyNews = 'module_companynews',
  Search = 'module_search',
  UserDetail = 'module_userdetail',
  Chat = 'module_chat',
  Settings = 'module_settings',
  Sites = 'module_sites',
  Bookmarks = 'module_bookmarks',
  People = 'module_people',
  Forms = 'module_forms',
  Tasks = 'module_delhaizemyday',
  DocumentLibrary = 'module_documentlibrary',
  LinkLibrary = 'module_linklibrary',
  Surveys = 'module_surveys',
  Summary = 'module_summary',
  OrganizationalChart = 'module_organizationalchart',
}

// Everything which is only rendering an Iframe using the IFrameModule
// is defined here
export enum IframeModules {
  Doccle = 'module_doccle',
  Events = 'module_events',
  Pixieset = 'module_pixieset',
  Epromote = 'module_epromote',
  Coins = 'module_coins',
  Dialoog = 'module_dialoog',
  Sabacloud = 'module_sabacloud',
  BeaulieuBighome = 'module_beaulieu_bighome',
  BelgoCatering = 'module_belgocatering',
  LunchDeloitte = 'module_lunch_deloitte',
  Cardstoconnect = 'module_cardstoconnect',
  BeaulieuSportswear = 'module_beaulieu_sportswear',
  BeaulieuStepchallenge = 'module_beaulieu_stepchallenge',
  SelfserviceFaq = 'module_selfservice_faq',
  IframeDemoOne = 'module_iframe_demo_one',
  IframeDemoTwo = 'module_iframe_demo_two',
  IframeDemoThree = 'module_iframe_demo_three',
  SocialWallOne = 'module_social_wall_one',
  SocialWallTwo = 'module_social_wall_two',
  Workelo = 'module_workelo',
  WorkeloOne = 'module_workelo_one',
  WorkeloTwo = 'module_workelo_two',
  TallyForm = 'module_tally_form',
}

export const Modules = { ...WebModules, ...IframeModules };
export type Modules = WebModules | IframeModules;
