import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import type { IconNames } from 'sprite/types';

import { notEmpty } from 'utils/typescript.utils';

import { Routes } from 'constants/routes.constants';
import { useIsModuleEnabled } from 'hooks/useIsModuleEnabled';
import { useInfiniteLinks } from 'queries/links/useInfiniteLinks';
import { useNavLinks } from 'queries/links/useNavLinks';
import { useWorkeloMagicLink } from 'queries/workelo/useWorkeloMagicLink';
import type { Href } from 'types/api.types';
import { type Link, LinkType } from 'types/links.types';
import { Modules } from 'types/module.types';

import { iframeModules, webModules } from './config';

interface NavItem {
  name?: string;
  label?: string;
  to: string;
  target?: string;
  startIcon?: IconNames;
  startImage?: Href;
  showInMobileMenu?: boolean;
  type?: LinkType.FileLink | LinkType.Form | LinkType.WebLink;
}

export const useEnabledIframeModules = () => {
  const checkModuleEnabled = useIsModuleEnabled();
  const enabledIframeModules = useMemo(
    () => iframeModules.filter((iframeModule) => checkModuleEnabled(iframeModule.moduleId)),
    [checkModuleEnabled],
  );

  return enabledIframeModules;
};

const useEnabledWebModules = () => {
  const checkModuleEnabled = useIsModuleEnabled();
  const enabledWebModules = useMemo(
    () => webModules.filter((webModule) => webModule.moduleIds.some(checkModuleEnabled)),
    [checkModuleEnabled],
  );

  return enabledWebModules;
};

// INFO: Hook is only for the Workelo module 'module_workelo' a link should be shown in the menu called Workelo
const useWorkeloNavItem = () => {
  const workeloEnabled = useIsModuleEnabled(Modules.Workelo);
  const { data: workeloMagicLink } = useWorkeloMagicLink({
    enabled: workeloEnabled,
  });

  if (!workeloEnabled) return [];
  if (!workeloMagicLink?.magicLink) return [];

  return [
    {
      label: 'module_workelo_overview_title',
      to: workeloMagicLink.magicLink,
      target: '_blank',
      startIcon: 'IcoWorkelo' as IconNames,
    },
  ];
};

const mapLinkToNavItemTo = (link: Link) => {
  if (link.type === LinkType.Form)
    return generatePath(Routes.FormsDetail, { itemId: String(link.id) });
  if (link.type === LinkType.WebLink) {
    if (link.openExternal) return link.webUrl;
    return generatePath(Routes.IframeDetail, { id: String(link.id) });
  }
  if (link.type === LinkType.FileLink) return link.storageFile?.href;
};

export const useNavItems = () => {
  const enabledIframeModules = useEnabledIframeModules();
  const workeloNavItem = useWorkeloNavItem();
  const enabledWebModules = useEnabledWebModules();
  const { data: featuredNavLinks } = useNavLinks({
    featured: true,
    type: [LinkType.WebLink, LinkType.Form, LinkType.FileLink],
  });

  const { data: links, isLoading: isLoadingLinks } = useInfiniteLinks({
    featured: false,
    limit: 1,
    type: [LinkType.FileLink, LinkType.WebLink],
  });
  const { data: forms, isLoading: isLoadingForms } = useInfiniteLinks({
    featured: false,
    limit: 1,
    type: LinkType.Form,
  });

  const mappedFeaturedNavLinks = useMemo(
    () =>
      featuredNavLinks
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((link) => ({
          name: link.name,
          target: link.openExternal ? '_blank' : undefined,
          startImage: link.image,
          type: link.type as NavItem['type'],
          to: mapLinkToNavItemTo(link) ?? '',
        })),
    [featuredNavLinks],
  );

  const emptyLinks = !links?.length && !isLoadingLinks;
  const emptyForms = !forms?.length && !isLoadingForms;

  const coreModuleNav = useMemo(
    () =>
      enabledWebModules.filter((webModule) => {
        const linkModule = webModule.moduleIds.find((id) => id === Modules.LinkLibrary);
        const formsModule = webModule.moduleIds.find((id) => id === Modules.Forms);
        if (emptyLinks && linkModule) return;
        if (emptyForms && formsModule) return;
        return webModule;
      }),
    [emptyForms, emptyLinks, enabledWebModules],
  );

  const navItems = useMemo<Array<NavItem>>(() => {
    return [
      // core modules
      ...coreModuleNav,
      // enabled iframe modules
      ...enabledIframeModules,
      // Workelo
      ...workeloNavItem,
      ...mappedFeaturedNavLinks,
    ].filter(notEmpty);
  }, [coreModuleNav, enabledIframeModules, workeloNavItem, mappedFeaturedNavLinks]);

  return navItems;
};
