import { useCallback, useLayoutEffect } from 'react';

import { useSpencerStore } from 'store/spencer';

interface LoadingState {
  spinner: boolean;
  splashScreen: boolean;
}

export function useLoadingScreen(state: LoadingState): void;
export function useLoadingScreen(): (state: LoadingState) => void;
export function useLoadingScreen(state?: LoadingState) {
  const { setLoadingState: setLoadingApp } = useSpencerStore((state) => state.actions);

  const setLoadingAppState = useCallback(
    (state: LoadingState) => {
      setLoadingApp(state);
    },
    [setLoadingApp],
  );

  useLayoutEffect(() => {
    if (state !== undefined) setLoadingAppState(state);
  }, [state, setLoadingAppState]);

  return setLoadingAppState;
}
