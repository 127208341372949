import { alpha, type Components, type Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: `${theme.shape.borderRadius * 2}px`,
      fieldset: {
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          '-webkit-text-fill-color',
        ]),
      },

      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral01[200],
        borderRadius: `${theme.shape.borderRadius * 2}px`,
      },
      '&.Mui-disabled input': {
        WebkitTextFillColor: theme.palette.neutral01[500],
      },
      '&.Mui-disabled fieldset': {
        borderColor: `${theme.palette.neutral01[400]} !important`,
      },
      '.MuiOutlinedInput-notchedOutline': {
        color: theme.palette.neutral01[600],
        border: `1px solid ${theme.palette.neutral01[400]}`,
      },

      '&.MuiOutlinedInput-root:hover': {
        ':not(.Mui-disabled):not(.Mui-error) fieldset': {
          borderColor: theme.palette.brand.main[100],
        },
      },
      '&.Mui-focused fieldset': {
        backgroundColor: alpha(theme.palette.brand.main[100], 0.1),
      },
      '& legend': {
        width: 0,
      },
    }),
  },
};
