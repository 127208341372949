import type { ThemeOptions } from '@mui/material';

export const breakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 600, // down = mobile
    md: 900, // between = tablet
    lg: 1024, // up = desktop
    xl: 1536,
  },
};
