import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { useSelectedLocale } from 'services/i18n';
import type { LinkParams } from 'types/links.types';

import { navLinksQuery } from './queries';

export const useNavLinks = (params: LinkParams) => {
  const { locale } = useSelectedLocale();

  const query = useQuery({
    ...navLinksQuery(locale, params),
    // meta: { store: true },
    staleTime: Duration.ONE_HOUR,
  });

  return {
    data: Array.isArray(query.data) ? query.data : [],
    isLoading: query.isLoading,
  };
};
