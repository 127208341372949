import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { StatusBar } from '@capacitor/status-bar';
import { type ReactNode, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { isAndroid, isNative } from 'utils/capacitor.utils';

import { useLoadingScreen } from 'hooks/useLoadingScreen';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import { localStoragePersister, queryClient } from 'services/react-query';
import { useSpencerStore } from 'store/spencer';

import ErrorState from './ErrorState';
import { useMsalLogin } from './hooks';
import IdentityRetry from './IdentityRetry';
import { getAccessToken, getB2cLocale, MSAL_DOMAIN_HINT_KEY } from './utils';

interface Props {
  children: ReactNode;
}

const IdentityGate = ({ children }: Props) => {
  const triggerLoadingScreen = useLoadingScreen();
  const intl = useIntl();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance: msalInstance } = useMsal();
  const scopes = useTenantConfig()?.config?.identity?.scopes ?? [];
  const authError = useSpencerStore((state) => state.auth.error);
  const ssoProvider = localStorage.getItem(MSAL_DOMAIN_HINT_KEY) || undefined;

  const interactionType = isNative ? InteractionType.None : InteractionType.Redirect;

  const { retryLogin } = useMsalLogin(interactionType, {
    scopes,
    prompt: isNative ? 'login' : undefined,
    domainHint: ssoProvider,
    extraQueryParameters: { ui_locales: getB2cLocale() },
  });

  const handleRetry = useCallback(async () => {
    // Clear the msal cache to trigger the loging again
    await msalInstance.clearCache();
    await retryLogin();
  }, [msalInstance, retryLogin]);

  const handleHardRefresh = useCallback(() => {
    queryClient.clear();
    localStoragePersister.removeClient();
    msalInstance.clearCache();
    localStorage.clear();
    sessionStorage.clear();
    // Explicitly use window here to cause a full page reload without any hash or state
    window.location.href = '/';
  }, [msalInstance]);

  useEffect(() => {
    if (isAndroid) {
      void StatusBar.setOverlaysWebView({ overlay: true });
    }
  }, []);

  // Ensure we capture sentry errors
  useEffect(() => {
    if (authError) {
      triggerLoadingScreen({ spinner: false, splashScreen: false });
    }
  }, [authError, intl, triggerLoadingScreen]);

  // Ensures when authenticated we force new tokens for this session
  // This ensures that if we have an expired id token
  useEffect(() => {
    if (isAuthenticated) {
      getAccessToken({ forceRefresh: true }).catch(() => {
        // dont care as we handle this
      });
    }
  }, [isAuthenticated]);

  const invalidInProgressState = [
    InteractionStatus.Login,
    InteractionStatus.Logout,
    InteractionStatus.None,
    // @ts-expect-error dont care
  ].includes(inProgress);

  // In native we want to show our identity retry screen
  if (isNative && invalidInProgressState && !isAuthenticated) {
    return <IdentityRetry error={authError} onHandleRetry={handleRetry} />;
  }

  // Only continue if we have everything ready
  if (isAuthenticated) return children;

  // Incase we have an auth error
  if (authError) {
    return <ErrorState error={authError} onRetry={handleHardRefresh} />;
  }

  return null;
};

export default IdentityGate;
