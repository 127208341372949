// Import capgo so its services are initialized and its first
import 'services/capgo';
// Import univeral link setup
import 'services/universal-links';
import 'services/keyboard';
import 'services/safe-area';

import { CssBaseline } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import * as newProcess from 'process';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { rootRoute } from 'routing';

import { isNative } from 'utils/capacitor.utils';
import { registerErrorOverlay } from 'utils/vite-error-overlay';

import IdentityProvider from 'services/identity/IdentityProvider';
import { IntlProvider } from 'services/intl';
import LazyMotion from 'services/motion';
import { persistOptions, queryClient } from 'services/react-query';
import { sentryCreateBrowserRouter } from 'services/sentry';
import { ThemeProvider } from 'theme';

import RootBoundary from 'components/@boundaries/RootBoundary';
import GooeySvgFilter from 'components/@common/GooeyTypography/GooeySvgFilter';
import DefaultSeo from 'components/@seo/DefaultSeo';
import AppLoadingState from 'components/@states/AppLoadingState';
import TenantGate from 'components/@tenants/TenantGate';

// shimming process and Buffer, needed to make @azure/event-hubs work. This adds less than 1kb to the bundle
Object.assign(self, {
  process: {
    ...newProcess,
    env: typeof process !== 'undefined' ? process.env : {}, // save the environment 🍃
  },
  global: self,
  Buffer,
});

// Only done on dev
registerErrorOverlay();

const router = sentryCreateBrowserRouter([rootRoute]);

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <RootBoundary>
      <LazyMotion>
        <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
          <TenantGate>
            <IdentityProvider>
              <DefaultSeo />
              <ThemeProvider>
                <CssBaseline />
                <IntlProvider>
                  <RouterProvider router={router} />
                </IntlProvider>
                <AppLoadingState />
              </ThemeProvider>
            </IdentityProvider>
          </TenantGate>
          <GooeySvgFilter />
          {!isNative && <ReactQueryDevtools buttonPosition="bottom-right" />}
        </PersistQueryClientProvider>
      </LazyMotion>
    </RootBoundary>
  </StrictMode>,
);
