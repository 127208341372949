import type { AuthError } from '@azure/msal-browser';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Box, Button, Typography } from '@mui/material';
import { useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLoadingScreen } from 'hooks/useLoadingScreen';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';

import ErrorAlert from './ErrorAlert';

interface Props {
  error: AuthError | null;
  onHandleRetry: () => void;
}

const IdentityRetry = ({ error, onHandleRetry }: Props) => {
  // Ensure our splash screen is hidden
  useLoadingScreen({ spinner: false, splashScreen: false });

  // Ensures we dont have a flickering black bar when closing the
  // login popup, this is caused by the keyboard resize
  useLayoutEffect(() => {
    Keyboard.setResizeMode({ mode: KeyboardResize.None });
    return () => {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    };
  }, []);

  const { config } = useTenantConfig();
  const logo = config?.assets?.logo?.onboarding;

  return (
    <Box
      bgcolor="brand.main.100"
      height="100dvh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      px={2}
      pt={(theme) => `calc(var(--safe-area-inset-top, 0px) + ${theme.spacing(3)})`}
      pb={(theme) => `calc(var(--safe-area-inset-bottom, 0px) + ${theme.spacing(3)})`}
    >
      <img src={logo} alt="logo" width="100%" style={{ margin: 'auto' }} />
      <Box alignSelf="flex-end">
        {error && <ErrorAlert error={error} />}

        <Typography variant="h3" textAlign="center" color="brand.main.invert">
          <FormattedMessage
            id="module_mobile_onboarding_title"
            values={{ tenant_name: config?.name }}
          />
        </Typography>
        <Typography variant="body2" textAlign="center" color="brand.main.invert">
          <FormattedMessage id="module_mobile_onboarding_description" />
        </Typography>
      </Box>
      <Button fullWidth variant="contrast" size="large" onClick={onHandleRetry} sx={{ my: 3 }}>
        <FormattedMessage id="core_welcome_get_started" />
      </Button>
    </Box>
  );
};

export default IdentityRetry;
