import { domAnimation, LazyMotion as FramerLazyMotion } from 'motion/react';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const LazyMotion = ({ children }: Props) => {
  return <FramerLazyMotion features={domAnimation}>{children}</FramerLazyMotion>;
};

export default LazyMotion;
