import type { Components, Theme } from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiInputBase-input': {
        fontSize: '16px',
        boxSizing: 'unset !important',
      },
      '& .MuiInputBase-root fieldset': {
        borderRadius: theme.shape.borderRadius * 2,
      },
      '& .Mui-focused .MuiInputAdornment-root': {
        color: theme.palette.brand.main[100],
      },
      '& input::placeholder': {
        color: theme.palette.neutral01[600],
        opacity: 1,
      },
    }),
  },
};
