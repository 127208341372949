import type { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useActiveModule } from 'hooks/useActiveModule';
import { useIsModuleEnabled } from 'hooks/useIsModuleEnabled';

interface Props {
  children: ReactNode;
}

// Ensure we don't show any module which should not be available
const ModuleProtected = ({ children }: Props) => {
  const checkModuleEnabled = useIsModuleEnabled();
  const moduleId = useActiveModule();

  if (checkModuleEnabled(moduleId)) {
    return children;
  } else {
    return <Navigate to={Routes.NotFound} />;
  }
};

export default ModuleProtected;
