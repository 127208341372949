import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

export const isNative = Capacitor.isNativePlatform();

export const isIos = Capacitor.getPlatform() === 'ios';

export const isAndroid = Capacitor.getPlatform() === 'android';

export const isWeb = Capacitor.getPlatform() === 'web';

const ImpactStyleLookup = {
  heavy: ImpactStyle.Heavy,
  medium: ImpactStyle.Medium,
  light: ImpactStyle.Light,
};

export const hapticsImpact = (style: keyof typeof ImpactStyleLookup) => {
  if (isWeb) return Promise.resolve();
  return Haptics.impact({ style: ImpactStyleLookup[style] });
};
