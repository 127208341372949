import type { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  styleOverrides: {
    select: ({ theme }) => ({
      fontSize: '16px',
      lineHeight: '24px',
      padding: theme.spacing(3, 1.5, 1, 1.5),
    }),
    icon: {
      top: 0,
      height: '100%',
    },
  },
};
