import { useContext } from 'react';
import { useInView } from 'react-intersection-observer';

import { MeasurementsContext } from './MeasurementsProvider';
import type { MeasurementsEventPayload } from './types';

export const useMeasurements = () => {
  return useContext(MeasurementsContext);
};

interface ImpressionEventProps {
  disabled?: boolean;
}

export const useImpressionEvent = (
  payload: MeasurementsEventPayload,
  options: ImpressionEventProps = { disabled: false },
) => {
  const sendEvent = useMeasurements();
  const { ref } = useInView({
    threshold: 0.75,
    triggerOnce: true,
    skip: options.disabled,
    delay: 3000,
    onChange: (inView, _entry) => {
      if (inView) {
        sendEvent(payload);
      }
    },
  });

  return { ref };
};
