import { Box, ClickAwayListener, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLoadingScreen } from 'hooks/useLoadingScreen';

import Icon from 'components/@icons';

interface Props {
  currentVersion: string;
  minimumVersion: string;
}

const VersionTooltip = ({ currentVersion, minimumVersion }: Props) => {
  // For a lack of a better place to do this, we do it here
  useLoadingScreen({ spinner: false, splashScreen: false });
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        slotProps={{
          popper: {
            placement: 'top',
            disablePortal: true,
          },
        }}
        title={
          <Stack gap={0.5} direction="column">
            <Typography variant="body2" color="brand.main.invert">
              <FormattedMessage
                id="module_mobile_minimum_version_check_current_version"
                values={{ version_number: currentVersion }}
              />
            </Typography>
            <Typography variant="body2" color="brand.main.invert">
              <FormattedMessage
                id="module_mobile_minimum_version_check_required_version"
                values={{ version_number: minimumVersion }}
              />
            </Typography>
          </Stack>
        }
      >
        <Box display="flex" alignItems="center">
          <Icon
            name="IcoInfo"
            sx={{ color: 'common.white', fontSize: 26 }}
            onClick={handleTooltipOpen}
          />
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default VersionTooltip;
