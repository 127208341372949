import { type Components, dialogContentClasses, type Theme } from '@mui/material';

import { hideScrollbar } from 'utils/styles.utils';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 4,
      overflowX: 'hidden',
      ...hideScrollbar,
    }),
    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(3, 3, 1, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 2, 1, 2),
      },
      [`& + .${dialogContentClasses.root}`]: {
        paddingTop: `${theme.spacing(2)} !important`,
        [theme.breakpoints.down('sm')]: {
          paddingTop: `${theme.spacing(1)} !important`,
        },
      },
    }),
  },
};

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...hideScrollbar,
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2),
      },
    }),
  },
};

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1, 3, 0, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2, 0, 2),
      },
      '&>:not(style)~:not(style)': {
        marginLeft: 0,
      },
    }),
  },
};
