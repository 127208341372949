import { SafeArea, type SafeAreaInsets } from 'capacitor-plugin-safe-area';

import { isIos, isNative } from 'utils/capacitor.utils';

if (isNative) {
  const setInsetsAsCssVars = ({ insets }: SafeAreaInsets) => {
    const fixedHeightData = {
      ...insets,
      bottom: isIos ? insets.bottom / 2 : insets.bottom,
    };

    for (const [key, value] of Object.entries(fixedHeightData)) {
      document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
    }
  };

  // Whenever this resolves, sets its on the dom
  SafeArea.getSafeAreaInsets().then(setInsetsAsCssVars);

  // Setsup the listener in case the viewport does change
  SafeArea.addListener('safeAreaChanged', setInsetsAsCssVars);
}
