import { queryOptions } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { apiClient } from 'services/api/client';
import type { ChatUserToken } from 'types/chat.types';
import type { FeedToken, MeasurementsToken } from 'types/token.types';

const PREFIX_KEY = 'tokens';

export const feedTokenQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'feed'],
  queryFn: () => apiClient.get(`api/cards/token`).json<FeedToken>(),
  refetchOnWindowFocus: true,
  refetchIntervalInBackground: true,
  // Defines a staleTime based on the expires in so we never have a stale token
  staleTime: (query) => {
    if (query.state.data?.expiresIn) {
      return query.state.data.expiresIn * 1000 - Duration.TEN_MIN;
    } else {
      return Duration.FIFTEEN_MIN;
    }
  },
  refetchInterval: (query) => {
    if (query.state.data?.expiresIn) {
      return query.state.data.expiresIn * 1000 - Duration.FIVE_MIN;
    } else {
      return Duration.FIFTEEN_MIN;
    }
  },
  // meta: { store: true },
});

export const measurementsTokenQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'measurements'],
  queryFn: () => apiClient.get(`api/measurements/token`).json<MeasurementsToken>(),
  refetchOnWindowFocus: true,
  refetchIntervalInBackground: true,
  staleTime: (query) => {
    if (query.state.data?.expiresIn) {
      return query.state.data.expiresIn * 1000 - Duration.TEN_MIN;
    } else {
      return Duration.FIFTEEN_MIN;
    }
  },
  refetchInterval: (query) => {
    if (query.state.data?.expiresIn) {
      return query.state.data.expiresIn * 1000 - Duration.FIVE_MIN;
    } else {
      return Duration.FIFTEEN_MIN;
    }
  },
  // meta: { store: true },
});

export const chatTokenQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'chat'],
  queryFn: () => apiClient.get(`api/chat/token`).json<ChatUserToken>(),
  refetchOnWindowFocus: true,
  refetchIntervalInBackground: true,
  staleTime: (query) => {
    if (query.state.data?.expiresIn) {
      return query.state.data.expiresIn * 1000 - Duration.TEN_MIN;
    } else {
      return Duration.FIFTEEN_MIN;
    }
  },
  refetchInterval: (query) => {
    if (query.state.data?.expiresIn) {
      return query.state.data.expiresIn * 1000 - Duration.FIVE_MIN;
    } else {
      return Duration.FIFTEEN_MIN;
    }
  },
  // meta: { store: true },
});
