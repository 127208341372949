import { Box, CircularProgress } from '@mui/material';

const RouteLoadingState = () => (
  <Box
    position="fixed"
    top={0}
    left={0}
    zIndex={9999}
    bgcolor="common.white"
    width="100vw"
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexGrow="1"
  >
    <CircularProgress sx={{ color: 'black' }} />
  </Box>
);

export default RouteLoadingState;
