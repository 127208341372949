import { useQuery } from '@tanstack/react-query';

import { chatTokenQuery } from './queries';

export const useChatUserToken = (config: Partial<typeof chatTokenQuery> = {}) => {
  const query = useQuery({
    ...chatTokenQuery,
    ...config,
    enabled: config.enabled ?? true,
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    error: query.error,
  };
};
