import { createTheme } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

import { breakpoints } from './breakpoints';

const breakpointValues = createTheme({ breakpoints }).breakpoints;

export const typography: TypographyOptions = {
  fontFamily: 'OpenSans, sans-serif',
  h1: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    fontSize: '3.75rem',
    lineHeight: '1.14667',
    letterSpacing: '-0.02em',
    [breakpointValues.down('sm')]: {
      fontSize: '2.125rem',
      lineHeight: '1.23529',
      letterSpacing: '-0.04em',
    },
  },
  h2: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    fontSize: '3.25rem',
    lineHeight: '1.16923',
    letterSpacing: '-0.02em',
    [breakpointValues.down('sm')]: {
      fontSize: '2rem',
      lineHeight: '1.25',
      letterSpacing: '-0.04em',
    },
  },
  h3: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    fontSize: '2.375rem',
    lineHeight: '1.23368',
    letterSpacing: '-0.02em',
    [breakpointValues.down('sm')]: {
      fontSize: '1.75rem',
      lineHeight: '1.28571',
      letterSpacing: '-0.04em',
    },
  },
  h4: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    fontSize: '2rem',
    lineHeight: '1.275',
    letterSpacing: '-0.02em',
    [breakpointValues.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '1.33333',
      letterSpacing: '-0.04em',
    },
  },
  h5: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    fontSize: '1.5rem',
    lineHeight: '1.36667',
    letterSpacing: '-0.02em',
    [breakpointValues.down('sm')]: {
      fontSize: '1.375rem',
      lineHeight: '1.36364',
      letterSpacing: 'unset',
    },
  },
  h6: {
    fontFamily: 'OpenSans',
    fontWeight: 800,
    fontSize: '1.25rem',
    lineHeight: '1.4',
  },
  bodyLead: {
    fontSize: '1.25rem',
    lineHeight: '1.4',
    [breakpointValues.down('sm')]: {
      fontSize: '1.125rem',
      lineHeight: '1.44444',
    },
  },
  body1: {
    fontSize: '1.125rem',
    lineHeight: '1.6',
  },
  body2: {
    fontSize: '1rem',
    lineHeight: '1.6',
  },
  overline: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.3333333333',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.625rem',
    lineHeight: '1.4',
  },
};
