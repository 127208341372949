import { useSpencerStore } from 'store/spencer';

import RouteLoadingState from 'components/@states/RouteLoadingState';

const AppLoadingState = () => {
  const isLoadingApp = useSpencerStore((state) => state.loadingState.showLoadingSpinner);

  if (isLoadingApp) return <RouteLoadingState />;
  return null;
};

export default AppLoadingState;
