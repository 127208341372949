import LoadingButton, { loadingButtonClasses } from '@mui/lab/LoadingButton';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { compareVersions } from 'compare-versions';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { isIos, isNative } from 'utils/capacitor.utils';

import { useAppVersion } from 'queries/capgo/useAppVersion';
import { useLatestBundle } from 'queries/capgo/useLatestBundle';
import { useManualUpdate } from 'queries/capgo/useManualUpdate';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';

import Icon from 'components/@icons';

import VersionTooltip from './VersionTooltip';

interface Props {
  children: ReactNode;
}

const TranslationKeys = {
  initial: {
    title: 'module_mobile_minimum_version_check_title',
    description: 'module_mobile_minimum_version_check_description',
    ctaButton: 'module_mobile_minimum_version_check_update_now',
  },
  downloading: {
    title: 'module_mobile_minimum_version_check_title',
    description: 'module_mobile_minimum_version_check_downloading_description',
    ctaButton: 'module_mobile_minimum_version_check_downloading',
  },
  error: {
    title: 'module_mobile_minimum_version_check_error_title',
    description: 'module_mobile_minimum_version_check_error_description',
    ctaButton: 'module_mobile_minimum_version_check_error_retry',
  },
};

const MinimumVersionCheck = ({ children }: Props) => {
  const { config } = useTenantConfig();
  const logo = config?.assets?.logo?.onboarding;
  const minimumVersion = config?.mobile?.minimumVersion;
  const playStoreUrl = config?.mobileApps?.android ?? '#';
  const appStoreUrl = config?.mobileApps?.ios ?? '#';

  const { nativeVersion, bundleVersion } = useAppVersion();
  const { latestBundle, isLoading: isLoadingLatestBundle } = useLatestBundle();
  const { isLoading: isLoadingManualUpdate, applyUpdate, isError } = useManualUpdate();

  const currentVersion = bundleVersion !== 'builtin' ? bundleVersion : nativeVersion;

  // If we dont have a minimum version don't bother checking
  if (!minimumVersion || !currentVersion) return children;

  const translationKeys =
    TranslationKeys[isLoadingManualUpdate ? 'downloading' : isError ? 'error' : 'initial'];

  const currentVersionWithoutBuildNumber = currentVersion.split('-')[0];
  // Check if current version is lower than minimum required
  if (compareVersions(currentVersionWithoutBuildNumber, minimumVersion) >= 0) return children;

  // Now check if our latest bundle would satisfy the minimum version
  const latestBundleVersion = latestBundle?.version;
  const latestBundleVersionWithoutBuildNumber = latestBundleVersion?.split('-')?.[0] ?? '0.0.0';
  // If our latest bundle version does not satisfy the min version we should update in the app store
  // If there are no updates then we probably should also update the app store
  const nativeUpdateRequired =
    compareVersions(latestBundleVersionWithoutBuildNumber, minimumVersion) < 0;

  // Show update required screen
  return (
    <Box
      bgcolor="brand.main.100"
      height="100dvh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      px={2}
      pt={(theme) => `calc(var(--safe-area-inset-top, 0px) + ${theme.spacing(3)})`}
      pb={(theme) => `calc(var(--safe-area-inset-bottom, 0px) + ${theme.spacing(3)})`}
    >
      <img src={logo} alt="logo" width="100%" style={{ margin: 'auto' }} />
      {isLoadingLatestBundle ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="160px">
          <CircularProgress sx={{ color: 'brand.main.invert' }} />
        </Box>
      ) : (
        <Box alignSelf="flex-end">
          <Stack gap={2} direction="column">
            <Stack gap={0.5} direction="column">
              <Stack direction="row" alignItems="center" gap={1} justifyContent="center">
                <Typography variant="h3" textAlign="center" color="brand.main.invert">
                  <FormattedMessage id={translationKeys.title} />
                </Typography>
                <VersionTooltip currentVersion={currentVersion} minimumVersion={minimumVersion} />
              </Stack>
              <Typography variant="body1" color="brand.main.invert" textAlign="center">
                <FormattedMessage id={translationKeys.description} />
              </Typography>
            </Stack>

            {nativeUpdateRequired ? (
              <Button
                variant="contrast"
                size="large"
                fullWidth
                href={isIos ? appStoreUrl : playStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<Icon name="IcoArrowForward" />}
                sx={{ my: 3 }}
              >
                <FormattedMessage id={translationKeys.ctaButton} />
              </Button>
            ) : (
              <LoadingButton
                variant="contrast"
                size="large"
                fullWidth
                loading={isLoadingManualUpdate}
                loadingPosition="end"
                onClick={() => applyUpdate()}
                endIcon={isError ? <Icon name="IcoRefresh" /> : <Icon name="IcoDownload" />}
                sx={{
                  // Ensures the text is white when loading
                  [`& .${loadingButtonClasses.label}`]: {
                    color: 'brand.main.invert',
                  },
                  my: 3,
                }}
              >
                <FormattedMessage id={translationKeys.ctaButton} />
              </LoadingButton>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

// Ensures we dont load in the component on web
const MinimumVersionCheckProvider = ({ children }: Props) => {
  if (!isNative) return children;

  return <MinimumVersionCheck>{children}</MinimumVersionCheck>;
};

export default MinimumVersionCheckProvider;
