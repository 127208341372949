import { Box } from '@mui/material';
import { type FunctionComponent, Suspense, useMemo, useState } from 'react';

import { isNative } from 'utils/capacitor.utils';

import { useFeatureFlag } from 'queries/configuration/useFeatureFlag';
import { useLocales } from 'queries/tenants/useLocales';
import { useZoomFactor } from 'queries/tenants/useZoomFactor';

import Privacy from 'components/@onboarding/Privacy';
import SelectLanguage from 'components/@onboarding/SelectLanguage';
import {
  NativeSelectNotifications,
  WebSelectNotifications,
} from 'components/@onboarding/SelectNotifications';
import SelectTopics from 'components/@onboarding/SelectTopics';
import { type OnboardingProps, OnboardingSteps } from 'components/@onboarding/types';
import { useSubscriptionFormTopics } from 'components/@settings/hooks';

const Notifications = isNative ? NativeSelectNotifications : WebSelectNotifications;

const Onboarding = () => {
  // Prefetch topics
  useSubscriptionFormTopics();
  const zoomFactor = useZoomFactor();
  const skipPrivacyStep = !useFeatureFlag('onboarding_privacypolicy');
  const skipLanguageStep = useLocales().locales.length === 1;

  // memoized instead of outside of the component, because we otherwise get a "Can't access before initialization" error
  const steps: Record<OnboardingSteps, FunctionComponent<OnboardingProps>> = useMemo(
    () => ({
      [OnboardingSteps.selectLanguage]: SelectLanguage,
      [OnboardingSteps.privacy]: Privacy,
      [OnboardingSteps.selectTopics]: SelectTopics,
      [OnboardingSteps.selectNotifications]: Notifications,
    }),
    [],
  );

  const [activeStep, setActiveStep] = useState(
    skipLanguageStep && skipPrivacyStep
      ? OnboardingSteps.selectTopics
      : skipLanguageStep
        ? OnboardingSteps.privacy
        : OnboardingSteps.selectLanguage,
  );

  const Component = steps[activeStep];

  return (
    <Box component="main" sx={{ zoom: zoomFactor }}>
      <Suspense>
        <Component
          skipLanguageStep={skipLanguageStep}
          skipPrivacyStep={skipPrivacyStep}
          setActiveStep={setActiveStep}
        />
      </Suspense>
    </Box>
  );
};

export default Onboarding;
