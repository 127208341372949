import { Button, Container } from '@mui/material';
import { captureException } from '@sentry/capacitor';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouteError } from 'react-router-dom';

import { useLoadingScreen } from 'hooks/useLoadingScreen';
import { localStoragePersister, queryClient } from 'services/react-query';

import EmptyState from 'components/@common/EmptyState';

const RouteBoundary = () => {
  useLoadingScreen({ spinner: false, splashScreen: false });
  const error = useRouteError();

  const handleRefresh = () => {
    try {
      queryClient.clear();
      localStoragePersister.removeClient();
      sessionStorage.clear();
      localStorage.clear();
    } catch {
      // dont care
    }
    window.location.href = '/';
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      captureException(error);
    }
  }, [error]);

  return (
    <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
      <EmptyState
        icon="IcoWarning"
        title={<FormattedMessage id="core_general_request_review_submit_failed_title" />}
        description={<FormattedMessage id="core_general_retry_refresh" />}
        action={
          <Button onClick={handleRefresh} variant="main" size="large">
            <FormattedMessage id="core_general_refresh" />
          </Button>
        }
      />
    </Container>
  );
};

export default RouteBoundary;
