import { isAndroid, isIos, isWeb } from './capacitor.utils';

export const getPlatformName = (fallback: string = 'web') => {
  if (isWeb) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      return navigator.platform.toLowerCase().includes('mac') ? 'macos' : 'windows';
    } catch {
      return fallback;
    }
  }
  if (isIos) return 'ios';
  if (isAndroid) return 'android';
  return fallback;
};
