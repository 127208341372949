import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from 'services/api/client';
import type { User, UserUpdateParams } from 'types/user.types';

import { userByServiceIdQuery, userQuery } from './queries';

interface Params {
  userId: User['spencerUuid'];
  serviceId: User['serviceUuid'];
  data: UserUpdateParams;
}

export const useUserUpdate = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ userId, data }: Params) => apiClient.put(`api/users/${userId}`, { json: data }),
    onMutate: async ({ data, userId, serviceId }) => {
      const queryKey = userQuery(userId).queryKey;
      const queryKeyServiceUser = userByServiceIdQuery(serviceId).queryKey;
      await Promise.all([
        queryClient.cancelQueries({ queryKey }),
        queryClient.cancelQueries({ queryKey: queryKeyServiceUser }),
      ]);

      const mappedData = { ...data, phonePrivateMobile: data.mobilePrivate };
      const updatedAt = Date.now();
      const previousUser = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old) => ({ ...old!, ...mappedData }), {
        updatedAt,
      });

      queryClient.setQueryData(queryKeyServiceUser, (old) => ({ ...old!, ...mappedData }), {
        updatedAt,
      });

      return { previousUser };
    },
    onError: (_err, { userId, serviceId }, context) => {
      if (context?.previousUser) {
        queryClient.setQueryData(userQuery(userId).queryKey, context.previousUser);
        queryClient.setQueryData(userByServiceIdQuery(serviceId).queryKey, context.previousUser);
      }
    },
  });

  return {
    updateUser: mutate,
    isLoading: isPending,
  };
};
