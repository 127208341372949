import type { AuthError } from '@azure/msal-browser';
import { Box, Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import EmptyState from 'components/@common/EmptyState';

import { identityMismatchError } from './utils';

interface Props {
  error: AuthError;
  onRetry: () => void;
}

const ErrorState = ({ error, onRetry }: Props) => {
  const intl = useIntl();
  const emailMismatch = identityMismatchError(error);
  const translations = emailMismatch
    ? {
        title: intl.formatMessage({ id: 'core_identity_error_authentication_mismatch' }),
        description: intl.formatMessage(
          { id: 'core_identity_error_authentication_mismatch_description' },
          { email: emailMismatch },
        ),
      }
    : {
        title: intl.formatMessage({ id: 'core_general_alert_error_body_login_authflow_fail' }),
        description: error.errorMessage,
      };

  return (
    <Box flex="1" flexDirection="column" alignItems="center" justifyContent="center" display="flex">
      <EmptyState
        icon="IcoAccount"
        title={translations.title}
        px={4}
        mx={2}
        description={
          <Box component="span" whiteSpace="pre" sx={{ textWrap: 'auto' }}>
            {translations.description}
          </Box>
        }
        action={
          <Button onClick={onRetry} variant="main" size="large">
            <FormattedMessage id="core_general_retry" />
          </Button>
        }
      />
    </Box>
  );
};

export default ErrorState;
