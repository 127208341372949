import { isWeb } from 'utils/capacitor.utils';
import { isDev } from 'utils/env.utils';
import { sharedPromisesCache } from 'utils/promises.utils';

// Registers the service worker
export const getServiceWorker = () => {
  if (isWeb && 'serviceWorker' in navigator) {
    return sharedPromisesCache('service-worker', async () => {
      try {
        return await navigator.serviceWorker.register(isDev ? '/dev-sw.js?dev-sw' : '/sw.js', {
          type: isDev ? 'module' : 'classic',
          scope: '/',
        });
      } catch (error) {
        console.error(error);
        console.warn('Service worker could not be installed, web pushes wont work');
      }
    });
  }
};
