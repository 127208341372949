import { App } from '@capacitor/app';
import { PushNotifications } from '@capacitor/push-notifications';
import { initializeApp } from '@firebase/app';
import { type ReactNode, useEffect } from 'react';

import { isAndroid, isNative, isWeb } from 'utils/capacitor.utils';

import { useFeatureFlag } from 'queries/configuration/useFeatureFlag';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import { useCurrentUser } from 'queries/users/useCurrentUser';
import { getServiceWorker } from 'services/service-worker';

interface Props {
  children: ReactNode;
}

const EventListenerProvider = ({ children }: Props) => {
  const { user, isLoading } = useCurrentUser();
  const webPushNotificationsFlag = useFeatureFlag('web_push_notifications');
  const { config } = useTenantConfig();

  useEffect(() => {
    const checkPushNotificationPrompt = async () => {
      if (user?.mobileOnboarded && !isLoading) {
        const permission = await PushNotifications.checkPermissions();
        if (permission.receive === 'prompt') {
          const res = await PushNotifications.requestPermissions();
          if (res.receive === 'granted') await PushNotifications.register();
        }
        if (permission.receive === 'granted') {
          await PushNotifications.register();
        }
      }
    };

    const setupWebPushNotifications = async () => {
      const { projectId, appId, messagingSenderId, apiKey } = config?.firebase ?? {};
      if (projectId && appId && messagingSenderId && apiKey) {
        // Initialize the firebase app for web pushes
        initializeApp({
          projectId,
          appId,
          messagingSenderId,
          apiKey,
        });
        const swRegistration = await getServiceWorker();
        if (!swRegistration) return;
        swRegistration.active?.postMessage({
          type: 'CONFIG',
          config: { icon: config?.assets?.favicon },
        });
      }
    };

    if (isNative) void checkPushNotificationPrompt();
    if (isWeb && !!webPushNotificationsFlag) void setupWebPushNotifications();

    if (isAndroid) {
      const appListener = App.addListener('backButton', (data) => {
        if (data.canGoBack) window.history.back();
        else void App.exitApp();
      });

      return () => {
        void appListener.then((listener) => listener.remove());
      };
    }
  }, [user, isLoading, webPushNotificationsFlag, config?.firebase, config?.assets?.favicon]);

  return children;
};

export default EventListenerProvider;
