import { useQuery } from '@tanstack/react-query';

import { notEmpty } from 'utils/typescript.utils';

import { Duration } from 'queries/constants';

import { initializedLocalesQuery } from './queries';
import { useLocales } from './useLocales';

export const useInitializeLocales = () => {
  const { locales, defaultLocale } = useLocales();
  // Ensures we don't attempt to fetch empty locale
  const allLocales = [...new Set([defaultLocale, ...locales])].filter(notEmpty);

  const query = useQuery({
    ...initializedLocalesQuery(allLocales),
    staleTime: Duration.FOREVER,
    enabled: !!allLocales?.length,
  });

  return {
    messages: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
