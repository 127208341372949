import type { Components, Theme } from '@mui/material';

export const MuiTabPanel: Components<Theme>['MuiTabPanel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }),
  },
};
