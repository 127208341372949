import { alpha, type Components, type Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-disabled, :hover.Mui-disabled': {
        backgroundColor: theme.palette.neutral01[400],
        color: theme.palette.neutral01[300],
      },

      transition: theme.transitions.create(['boxShadow', 'color', 'background-color']),
      '&:hover:not(.Mui-disabled)': {
        boxShadow: theme.mixins.brightness.medium,
      },
      '&:active:not(.Mui-disabled)': {
        boxShadow: theme.mixins.brightness.medium,
      },
    }),
  },
  variants: [
    {
      props: { color: 'default' },
      style: ({ theme }) => ({
        color: theme.palette.neutral01[800],
        '&:hover': {
          boxShadow: 'transparent',
        },
      }),
    },
    {
      props: { color: 'main' },
      style: ({ theme }) => ({
        color: theme.palette.brand.action.main[100],
        '&:hover': {
          backgroundColor: theme.palette.brand.action.main[100],
        },
      }),
    },
    {
      props: { color: 'contrast' },
      style: ({ theme }) => ({
        color: theme.palette.brand.action.contrast[100],
        '&:hover': {
          backgroundColor: theme.palette.brand.action.contrast[100],
        },
      }),
    },
    {
      props: { color: 'contrast', size: 'large' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.brand.action.contrast[100],
        color: theme.palette.brand.action.contrast.invert,
        '&:hover': {
          backgroundColor: theme.palette.brand.action.contrast[100],
        },
      }),
    },
    {
      props: { color: 'contrast', size: 'small' },
      style: ({ theme }) => ({
        color: theme.palette.brand.action.contrast[100],
        '&:hover': {
          backgroundColor: alpha(theme.palette.brand.action.contrast[100], 0.1),
        },
      }),
    },
    {
      props: { color: 'contrast', size: 'medium' },
      style: ({ theme }) => ({
        color: theme.palette.brand.action.contrast[100],
        '&:hover': {
          backgroundColor: alpha(theme.palette.brand.action.contrast[100], 0.1),
        },
      }),
    },
  ],
};
