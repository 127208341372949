import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

import { isNative } from 'utils/capacitor.utils';

import { useSpencerStore } from 'store/spencer';

if (isNative) {
  // We use window.location.href so our url is changed outside react land
  // If we use react-router then we are to late and the event gets lost in a race condition
  // Also if the user is not authenticated this will work correctly as the url is changed
  // and our authentication / onboarding is added on top without a route change
  CapacitorApp.addListener('appUrlOpen', (event) => {
    // This ensures we dont capture anything coming from msal
    const isWeb = event.url.startsWith('https://');
    if (!isWeb) return;
    const url = new URL(event.url);
    window.location.href = `${url.pathname}${url.search}${url.hash}`;
  });

  // Some notes on how the authentication flow works
  // 1: When it's a native app(ios/android) we listen to incoming deep link events coming from the msal redirect
  // 2: Deeplink works and it opens our app correctly but we have to handle the state in the url ourselves
  // 3: This is done by explicitly setting the location hash, and reloading the view
  // Alternatively you could also hook into hashchange event but this seems to be causing a lot of conflicts
  // This approach is also documented in Github issues on MSAL. (ex: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4458#issuecomment-1026614100)
  CapacitorApp.addListener('appUrlOpen', async (event) => {
    // Ensures we only capture events from msal
    const isMsalLink = event.url.startsWith('msauth');
    if (!isMsalLink) return;
    const url = new URL(event.url);

    if (url.hash) {
      window.location.hash = url.hash;
      // Start our loading screen causes a smoother transition
      useSpencerStore.getState().actions.setLoadingState({ splashScreen: false, spinner: true });
      window.location.reload();
    }
    try {
      await Browser.close();
    } catch {
      // could not close the browser but don't care it probably already closed
    }
  });
}
