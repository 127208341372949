import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { fetchAllNextQueries } from 'utils/api.utils';

import { Duration } from 'queries/constants';
import {
  defaultGetNextPageParam,
  defaultInitialPageParam,
  resolveHrefsQueries,
} from 'queries/utils';
import { apiClient, cleanParams } from 'services/api/client';
import { queryClient } from 'services/react-query';
import type { Collection, Href } from 'types/api.types';
import type { Link, LinkParams } from 'types/links.types';
import { type SearchParams, type SearchResult, SpencerModel } from 'types/search.types';

const KEY_PREFIX = 'links';

export const linkByIdQuery = (locale: string, linkId: string) =>
  queryOptions({
    queryKey: [KEY_PREFIX, locale, linkId],
    queryFn: () => apiClient.get(`api/link-library/links/${linkId}`).json<Link>(),
    staleTime: Duration.TEN_MIN,
    enabled: !!linkId,
  });

export const infiniteLinksQuery = (locale: string, params: LinkParams) =>
  infiniteQueryOptions({
    queryKey: [KEY_PREFIX, 'infinite', locale, cleanParams(params)],
    initialPageParam: defaultInitialPageParam,
    queryFn: async ({ pageParam }) => {
      const response = await apiClient
        .get(`api/link-library/links`, {
          searchParams: { ...cleanParams(params), p: pageParam },
        })
        .json<Collection<Array<Href>>>();

      return resolveHrefsQueries(response, ({ id }) =>
        queryClient.ensureQueryData(linkByIdQuery(locale, id ?? '')),
      );
    },
    getNextPageParam: defaultGetNextPageParam,
    staleTime: Duration.TEN_MIN,
  });

export const infiniteLinksSearchQuery = (locale: string, params: SearchParams) =>
  infiniteQueryOptions({
    queryKey: [KEY_PREFIX, 'infinite', 'search', locale, cleanParams(params)],
    initialPageParam: defaultInitialPageParam,
    queryFn: async ({ pageParam }) => {
      const response = await apiClient
        .get(`api/search`, {
          searchParams: cleanParams({
            ...params,
            'spencer-model': SpencerModel.LINKS,
            p: pageParam,
            limit: 10,
          }),
        })
        .json<Collection<Array<SearchResult>>>();

      return resolveHrefsQueries(response, ({ id }) =>
        queryClient.ensureQueryData(linkByIdQuery(locale, id ?? '')),
      );
    },
    getNextPageParam: defaultGetNextPageParam,
    staleTime: Duration.TEN_MIN,
  });

export const navLinksQuery = (locale: string, params: LinkParams) =>
  queryOptions({
    queryKey: [KEY_PREFIX, 'navigation', locale, cleanParams(params)],
    queryFn: async () => {
      const data = await fetchAllNextQueries(
        async (nextParams) => {
          const response = await apiClient
            .get(`api/link-library/links`, {
              searchParams: {
                ...cleanParams(params),
                ...nextParams,
              },
            })
            .json<Collection<Array<Href>>>();

          return resolveHrefsQueries(response, ({ id }) =>
            queryClient.ensureQueryData(linkByIdQuery(locale, id ?? '')),
          );
        },
        { groupBy: 'data' },
      );

      if (!Array.isArray(data)) {
        throw new Error('Could not fetch the navigation!');
      }

      return data;
    },
    staleTime: Duration.TEN_MIN,
  });
