import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentUser } from 'queries/users/useCurrentUser';
import { apiClient } from 'services/api/client';
import type { Topic } from 'types/topics.types';

import { userTopicIdsQuery } from './queries';

interface Params {
  topicIds: Array<Topic['id']>;
}

export const useUnsubscribeUserTopics = () => {
  const { userId } = useCurrentUser();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ topicIds }: Params) =>
      Promise.all(
        topicIds.map((topicId) =>
          apiClient.delete(`api/users/${userId}/news/topic-subscriptions/${topicId}`),
        ),
      ),
    onMutate: async (params) => {
      const queryKey = userTopicIdsQuery(userId).queryKey;
      await queryClient.cancelQueries({ queryKey });
      const previousTopicIds = queryClient.getQueryData(queryKey);

      if (!previousTopicIds) return;

      queryClient.setQueryData(queryKey, (old) =>
        old?.filter((item) => !params.topicIds.includes(item)),
      );

      return { previousTopicIds, queryKey };
    },
    onError: (_error, _params, context) => {
      if (context?.previousTopicIds) {
        const queryKey = userTopicIdsQuery(userId).queryKey;
        queryClient.setQueryData(queryKey, context.previousTopicIds);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(userTopicIdsQuery(userId));
    },
  });

  return {
    unsubscribeUserTopics: mutation.mutate,
    isLoading: mutation.isPending,
  };
};
