import { queryOptions } from '@tanstack/react-query';
import { NativeConfiguration } from 'capacitor-native-configuration';
import ky from 'ky';
import type { IntlConfig } from 'react-intl';

import { isWeb } from 'utils/capacitor.utils';
import { BUILD_HASH, DEV_BASE_URL, isDev, isProd } from 'utils/env.utils';

import { Duration } from 'queries/constants';
import { replacePlaceholderBrackets } from 'services/i18n/utils.i18n';
import type { EnvTenant } from 'types/env.types';

const PREFIX_KEY = 'tenant';

const fetchLocale = async (locale: string) => {
  const path = `/translations/${locale}.json?v${BUILD_HASH}`;
  const response = await ky.get(path).json<Record<string, string>>();

  return {
    locale,
    messages: replacePlaceholderBrackets(response),
  };
};

export const initializedLocalesQuery = (locales: Array<string>) =>
  queryOptions({
    queryKey: [PREFIX_KEY, 'locales', locales],
    queryFn: async () => {
      const response = await Promise.all(locales.map(fetchLocale));
      return response.reduce<Record<string, IntlConfig>>(
        (memo, intlInfo) => ({
          ...memo,
          [intlInfo.locale]: intlInfo,
        }),
        {},
      );
    },
    enabled: !!locales.length,
    staleTime: Duration.FOREVER,
  });

// Resolves the tenant host, depending on our platform this can be different
// - Web: if we are in local dev mode return DEV_BASE_URL else window.location.host
// - App: Use the native configuration property
const resolveTenantHost = async () => {
  if (isDev && isWeb) return DEV_BASE_URL as string;
  if (isWeb) return window.location.host;
  const { webhost } = await NativeConfiguration.get({ key: 'webhost' });
  return webhost;
};

export const tenantConfigQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'tenant-config'],
  queryFn: async () => {
    // Override for dev / localhost
    const tenantHost = await resolveTenantHost();
    // The tenant config is always on delighter, we pass in the x-tenant-host for the right tenant config
    const tenantBase = isProd ? 'delighter' : 'delighter-stag';
    const tenantConfigUrl = `https://${tenantBase}-api.spencer.co/api/public/tenantconfig`;

    const response = await ky
      .get(tenantConfigUrl, {
        headers: {
          'X-Tenant-Host': tenantHost,
          'Cache-Control': 'no-cache',
        },
      })
      .json<EnvTenant>();

    return {
      ...response,
      ...response.configuration,
    };
  },
  staleTime: Duration.FOREVER,
});
