import { alpha, type Components, type Theme } from '@mui/material';

export const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  styleOverrides: {
    textPrimary: ({ theme }) => ({
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.brand.main[100], 0.1),
        color: theme.palette.brand.main[100],
        '&:hover': {
          backgroundColor: alpha(theme.palette.brand.main[100], 0.1),
          color: theme.palette.brand.main[100],
        },
      },
    }),
  },
};
