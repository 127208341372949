import { useLocales } from 'queries/tenants/useLocales';
import { useSpencerStore } from 'store/spencer';

export const useSelectedLocale = () => {
  const { defaultLocale } = useLocales();
  const locale = useSpencerStore((state) => state.locale) ?? defaultLocale;

  return {
    locale,
  };
};
