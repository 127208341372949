import type { Components, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.neutral01[800],
      fontWeight: 700,
      minHeight: 48,
      minWidth: 100,
      [theme.breakpoints.up('lg')]: {
        minWidth: 160,
      },
    }),
  },
};
