import type { EventHubBufferedProducerClient } from '@azure/event-hubs';
import { Capacitor } from '@capacitor/core';
import { decamelizeKeys } from 'humps';
import { createContext, type ReactNode, useCallback, useLayoutEffect, useState } from 'react';

import { APP_VERSION } from 'utils/env.utils';

import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import { useMeasurementsToken } from 'queries/tokens/useMeasurementsToken';
import { useCurrentUser } from 'queries/users/useCurrentUser';
import { useSelectedLocale } from 'services/i18n';

import type { MeasurementsEventPayload } from './types';
import { eventHubProducerClient } from './utils';

export const MeasurementsContext = createContext<
  (payload: MeasurementsEventPayload) => Promise<void>
>(() => Promise.resolve());

const MeasurementsProvider = ({ children }: { children: ReactNode }) => {
  const { config } = useTenantConfig();
  const { user } = useCurrentUser();
  const { data: token, isLoading: isLoadingToken } = useMeasurementsToken();
  const [eventHubClient, setEventHubClient] = useState<EventHubBufferedProducerClient | null>(null);
  const { locale } = useSelectedLocale();
  const measurementsEnabled = user && 'isTester' in user ? !user.isTester : true;

  useLayoutEffect(() => {
    if (!measurementsEnabled) return;
    const client = eventHubProducerClient(token);
    if (client) setEventHubClient(client);

    // When we leave the page flush the buffer
    return () => {
      if (client) client.flush();
    };
  }, [token, measurementsEnabled]);

  const sendEvent = useCallback(
    async ({ entity, eventName, data }: MeasurementsEventPayload) => {
      try {
        if (!measurementsEnabled) return;
        if (!eventHubClient) {
          console.error('EventHub: no client found, dropping events!');
          return;
        }

        const defaultBody = {
          publisherId: Capacitor.getPlatform(),
          publisherVersion: APP_VERSION,
          customer: config?.company.id,
          serviceUuid: user?.serviceUuid,
          language: locale.slice(0, 2),
        };

        const body = decamelizeKeys({
          entity,
          eventName,
          data,
          eventDateTime: new Date().toISOString(),
          ...defaultBody,
        });
        await eventHubClient?.enqueueEvent({ body });
      } catch (error) {
        console.error('EventHub: error in sendEvent:', error);
      }
    },
    [measurementsEnabled, eventHubClient, config?.company.id, user?.serviceUuid, locale],
  );

  // Ensure we block the render of the children in case we are not ready to send events
  if (measurementsEnabled && (isLoadingToken || !eventHubClient)) return null;

  return <MeasurementsContext.Provider value={sendEvent}>{children}</MeasurementsContext.Provider>;
};

export default MeasurementsProvider;
