import { EventHubBufferedProducerClient } from '@azure/event-hubs';
import memoize from 'lodash-es/memoize';

import { isDev, isStag } from 'utils/env.utils';

import { measurementsTokenQuery } from 'queries/tokens/queries';
import { queryClient } from 'services/react-query';
import type { MeasurementsToken } from 'types/token.types';

const getEventHubProducerClientInstance = memoize(
  ({ namespace, eventHubName }: { namespace: string; eventHubName: string }) => {
    const clientInstance = new EventHubBufferedProducerClient(
      namespace,
      eventHubName,
      {
        getToken: async () => {
          // Important to always use getMeasurementToken so we always have a fresh version
          // of the token
          const token = await queryClient.ensureQueryData(measurementsTokenQuery);
          if (!token) return null;
          return { token: token.token, expiresOnTimestamp: token.expiresAt * 1000 };
        },
      },
      {
        retryOptions: { maxRetries: 5, retryDelayInMs: 1000 },
        onSendEventsSuccessHandler: (ctx) => {
          if (isDev || isStag) {
            console.info('EventHub: success in sendEvents:', ctx);
          }
        },
        onSendEventsErrorHandler: (ctx) => {
          console.error('EventHub: error in sendEvents:', ctx);
        },
      },
    );

    return clientInstance;
  },
  // We need to provide a memoize resolver
  (args) => `${args.namespace}-${args.eventHubName}`,
);

// We need the toke to know the namespace and eventHubName
// We don't want to pass it as a parameter to memoize since we always want to receive the same client instance
// Even when the token refreshes
export const eventHubProducerClient = (token: MeasurementsToken | undefined) => {
  if (!token) return null;
  return getEventHubProducerClientInstance({
    namespace: token.namespace,
    eventHubName: token.eventHubName,
  });
};
