import { createContext, type ReactNode, useContext } from 'react';
import { useCreateChatClient } from 'stream-chat-react';

import { useChatModuleEnabled } from 'hooks/useChatModuleEnabled';
import { useChatUserToken } from 'queries/tokens/useChatUserToken';
import { useCurrentUser } from 'queries/users/useCurrentUser';

// Define the context value type
interface ChatClientContextValue {
  chatClient: ReturnType<typeof useCreateChatClient> | null;
}

// Create the context with an undefined initial value
const ChatClientContext = createContext<ChatClientContextValue | undefined>(undefined);

interface Props {
  children: ReactNode;
}

// Provider when chat is enabled
const ChatClientProviderWithChat = ({ children }: Props) => {
  const { data: userTokenData } = useChatUserToken();
  const { user } = useCurrentUser();
  const serviceUuid = user?.serviceUuid;

  const chatClient = useCreateChatClient({
    apiKey: userTokenData?.apiKey || '',
    tokenOrProvider: userTokenData?.token,
    userData: { id: serviceUuid || '' },
  });

  return <ChatClientContext.Provider value={{ chatClient }}>{children}</ChatClientContext.Provider>;
};

// Provider when chat is disabled
const ChatClientProviderWithoutChat = ({ children }: Props) => {
  return (
    <ChatClientContext.Provider value={{ chatClient: null }}>{children}</ChatClientContext.Provider>
  );
};

// Main provider that decides which provider to use based on the isChatEnabled flag
export const ChatClientProvider = ({ children }: Props) => {
  // Fetch the flag to enable/disable chat functionality
  const { isChatEnabled } = useChatModuleEnabled();

  if (isChatEnabled) {
    return <ChatClientProviderWithChat>{children}</ChatClientProviderWithChat>;
  }
  return <ChatClientProviderWithoutChat>{children}</ChatClientProviderWithoutChat>;
};

// Hook to use the chat client context
export const useChatClient = () => {
  const context = useContext(ChatClientContext);
  if (context === undefined) {
    throw new Error('useChatClient must be used within a ChatClientProvider');
  }
  return context;
};
