import { alpha, Box, Button, Chip, Skeleton, Typography } from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSubscribeUserTopics } from 'queries/topics/useSubscribeUserTopics';
import { useUnsubscribeUserTopics } from 'queries/topics/useUnsubscribeUserTopics';
import { useNotifications } from 'services/snackbar';
import type { Topic } from 'types/topics.types';

import Icon from 'components/@icons/Icon';
import { useSubscriptionFormTopics } from 'components/@settings/hooks';

const NewsSubscriptionsForm = () => {
  const { error } = useNotifications();
  const intl = useIntl();
  const { unsubscribeUserTopics } = useUnsubscribeUserTopics();
  const { subscribeUserTopics } = useSubscribeUserTopics();
  const { isLoading, userTopicIds, mandatoryTopics, otherTopics } = useSubscriptionFormTopics();

  const isSelected = (topicId: Topic['id']) => userTopicIds?.includes(topicId);

  const handleOnErrorNotification = useCallback(() => {
    error({
      title: intl.formatMessage({ id: 'core_general_alert_error_title' }),
      message: intl.formatMessage({ id: 'core_general_failed' }),
    });
  }, [error, intl]);

  const handleToggleTopic = (topicId: Topic['id']) => {
    if (isSelected(topicId)) {
      unsubscribeUserTopics(
        { topicIds: [topicId] },
        {
          onError: handleOnErrorNotification,
        },
      );
    } else {
      subscribeUserTopics(
        { topicIds: [topicId] },
        {
          onError: handleOnErrorNotification,
        },
      );
    }
  };

  const handleSelectAll = () => {
    const topicIds = otherTopics.reduce<Array<Topic['id']>>((memo, topic) => {
      if (!userTopicIds.includes(topic.id)) memo.push(topic.id);
      return memo;
    }, []);
    subscribeUserTopics({ topicIds }, { onError: handleOnErrorNotification });
  };

  const handleSelectNone = () => {
    const topicIds = userTopicIds.reduce<Array<Topic['id']>>((memo, topicId) => {
      const topic = otherTopics.find((topic) => topic.id === topicId);
      if (topic?.canUnsubscribe) memo.push(topicId);
      return memo;
    }, []);

    unsubscribeUserTopics({ topicIds }, { onError: handleOnErrorNotification });
  };

  return (
    <>
      {(!!mandatoryTopics.length || isLoading) && (
        <>
          <Typography variant="body2" fontWeight="bold" mb={1}>
            <FormattedMessage id="core_settings_topics_mandatory" />
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {isLoading
              ? Array.from({ length: 3 }, (_, i) => (
                  <Skeleton key={i} variant="rounded" sx={{ borderRadius: 4, height: 32 }}>
                    <Chip sx={{ width: 110 }} />
                  </Skeleton>
                ))
              : mandatoryTopics?.map((topic) => (
                  <Chip
                    key={topic.id}
                    clickable
                    size="medium"
                    onClick={() => handleToggleTopic(topic.id)}
                    label={topic.name}
                    sx={(theme) => ({
                      '&.Mui-disabled': {
                        color: theme.palette.brand.topicChip.selection.invert,
                        backgroundColor: theme.palette.brand.topicChip.selection[100],
                      },
                    })}
                    disabled
                  />
                ))}
          </Box>
        </>
      )}

      {(!!otherTopics.length || isLoading) && (
        <>
          <Typography variant="body2" fontWeight="bold" mt={3} mb={1}>
            <FormattedMessage id="core_settings_topics_optional" />
          </Typography>
          <Box gap={2} display="flex" mb={1}>
            <Button variant="text" size="small" onClick={isLoading ? undefined : handleSelectAll}>
              <FormattedMessage id="core_general_select_all" />
            </Button>
            <Button variant="text" size="small" onClick={isLoading ? undefined : handleSelectNone}>
              <FormattedMessage id="core_general_select_none" />
            </Button>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {isLoading
              ? Array.from({ length: 9 }, (_, i) => (
                  <Skeleton key={i} variant="rounded" sx={{ borderRadius: 4, height: 32 }}>
                    <Chip sx={{ width: 110 }} />
                  </Skeleton>
                ))
              : otherTopics?.map((topic) => {
                  const isTopicSelected = isSelected(topic.id);
                  return (
                    <Chip
                      key={topic.id}
                      clickable
                      size="medium"
                      onClick={() => handleToggleTopic(topic.id)}
                      label={
                        <Box display="flex" alignItems="center" gap={0.5}>
                          {topic.name} {isTopicSelected && <Icon name="IcoClose" fontSize={16} />}
                        </Box>
                      }
                      sx={(theme) => ({
                        color: isTopicSelected
                          ? theme.palette.brand.topicChip.selection.invert
                          : theme.palette.brand.topicChip.selection[100],
                        backgroundColor: isTopicSelected
                          ? theme.palette.brand.topicChip.selection[100]
                          : 'transparent',
                        border: `1px solid ${theme.palette.brand.topicChip.selection[100]}`,
                        ':hover': {
                          backgroundColor: {
                            xs: isTopicSelected
                              ? theme.palette.brand.topicChip.selection[100]
                              : theme.palette.brand.topicChip.selection.invert,
                            md: isTopicSelected
                              ? alpha(theme.palette.brand.topicChip.selection[100], 0.9)
                              : alpha(theme.palette.brand.topicChip.selection[100], 0.1),
                          },
                          color: {
                            xs: isTopicSelected
                              ? theme.palette.brand.topicChip.selection.invert
                              : theme.palette.brand.topicChip.selection[100],
                          },
                        },
                      })}
                    />
                  );
                })}
          </Box>
        </>
      )}
    </>
  );
};

export default NewsSubscriptionsForm;
