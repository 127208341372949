import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { ChatClientProvider } from 'services/chat/ChatClientProvider';
import EventListenerProvider from 'services/event-listeners/EventListenerProvider';
import { IdentityGate } from 'services/identity';
import { MeasurementsProvider } from 'services/measurements';
import { PrefetchGate } from 'services/prefetcher';
import PushNotificationProvider from 'services/push-notifications/PushNotificationProvider';
import SnackbarProvider from 'services/snackbar/SnackbarProvider';
import TenantUpdateProvider from 'services/tenant-update-provider/TenantUpdateProvider';

import MainLayout from 'components/@layout/MainLayout';
import MinimumVersionCheck from 'components/@layout/MinimumVersionCheck';

/**
 * This is the entry point of our app.
 * The following components prevent the rest of app from rendering until they are done:
 * - TenantGate: fetches the tenant config and initializes the services
 * - IdentityGate: initializes the identity service
 * - PrefetchGate: prefetches data that is needed before rendering the rest of the app
 */
const Root = () => {
  return (
    <Suspense>
      <MinimumVersionCheck>
        <SnackbarProvider>
          <IdentityGate>
            <PrefetchGate>
              <MeasurementsProvider>
                <TenantUpdateProvider>
                  <ChatClientProvider>
                    <EventListenerProvider>
                      <PushNotificationProvider>
                        <MainLayout>
                          <Outlet />
                        </MainLayout>
                      </PushNotificationProvider>
                    </EventListenerProvider>
                  </ChatClientProvider>
                </TenantUpdateProvider>
              </MeasurementsProvider>
            </PrefetchGate>
          </IdentityGate>
        </SnackbarProvider>
      </MinimumVersionCheck>
    </Suspense>
  );
};

export default Root;
