import { Alert, Collapse, IconButton, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFeatureFlag } from 'queries/configuration/useFeatureFlag';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import { useSpencerStore } from 'store/spencer';

import Icon from 'components/@icons';

const CookieBanner = () => {
  const intl = useIntl();
  const hideCookiebannerFlag = useFeatureFlag('onboarding_hide_cookiebanner');
  const { setCookieBanner } = useSpencerStore((state) => state.actions);
  const showCookieBanner = useSpencerStore((state) => state.showCookieBanner);
  const cookieLink = useTenantConfig().config?.links?.cookie;

  const handleShowBanner = () => {
    setCookieBanner(false);
  };

  if (!showCookieBanner || hideCookiebannerFlag) return null;

  return (
    <Collapse in={showCookieBanner}>
      <Alert
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleShowBanner}
            aria-label={intl.formatMessage({ id: 'core_general_close' })}
          >
            <Icon name="IcoClose" fontSize={24} color="brand.action.contrast.invert" />
          </IconButton>
        }
        icon={false}
        severity="info"
        variant="standard"
      >
        <Typography variant="body2" color="brand.action.contrast.invert" mb={0.5}>
          <FormattedMessage id="core_cookies_banner_title" />
        </Typography>

        <Typography
          variant="overline"
          color="brand.action.contrast.invert"
          sx={(theme) => ({
            '& a': {
              textDecoration: 'underline',
              color: theme.palette.brand.action.contrast.invert,
            },
          })}
        >
          <FormattedMessage
            id="core_cookies_banner_description"
            values={{
              a: (chunks) => (
                <a target="_blank" href={cookieLink ?? ''} rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default CookieBanner;
