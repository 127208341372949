import { createContext } from 'react';

interface ContextProps {
  hasUpdates: boolean;
  reload: () => void;
}

export const TenantUpdateContext = createContext<ContextProps>({
  hasUpdates: false,
  reload: () => undefined,
});
