import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { queryOptions } from '@tanstack/react-query';

import { Duration } from 'queries/constants';

const PREFIX_KEY = 'capgo';

export const currentVersionQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'version'],
  queryFn: () => CapacitorUpdater.current(),
  staleTime: Duration.FOREVER,
});

export const deviceIdQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'deviceId'],
  queryFn: () => CapacitorUpdater.getDeviceId(),
  staleTime: Duration.FOREVER,
});

export const channelQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'channel'],
  queryFn: () => CapacitorUpdater.getChannel(),
  staleTime: Duration.FOREVER,
});

export const latestBundleQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'latestBundle'],
  queryFn: async () => {
    try {
      return await CapacitorUpdater.getLatest();
    } catch {
      return null;
    }
  },
  staleTime: Duration.FOREVER,
});
