import { captureException, flush, setUser } from '@sentry/capacitor';
import { HTTPError } from 'ky';
import { type ReactNode, useEffect } from 'react';

import { useChatModuleEnabled } from 'hooks/useChatModuleEnabled';
import { useEnabledModules } from 'queries/configuration/useEnabledModules';
import { useMe } from 'queries/configuration/useMe';
import { useInitializeLocales } from 'queries/tenants/useInitializeLocales';
import { useLocales } from 'queries/tenants/useLocales';
import { useChatUserToken } from 'queries/tokens/useChatUserToken';
import { useMeasurementsToken } from 'queries/tokens/useMeasurementsToken';
import { useCurrentUser } from 'queries/users/useCurrentUser';
import { useLogOut } from 'queries/users/useLogOut';
import { useSelectedLocale } from 'services/i18n';
import { useNotifications } from 'services/snackbar';
import { useSpencerStore } from 'store/spencer';

import { usePrefetchNonBlocking } from './hook';

interface Props {
  children: ReactNode;
}

const digestMessage = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

// Prefetch API calls that are needed before rendering the rest of the app
const PrefetchGate = ({ children }: Props) => {
  const { setLocale } = useSpencerStore((state) => state.actions);

  const { locales, defaultLocale } = useLocales();
  const { locale } = useSelectedLocale();
  const { logOut } = useLogOut();
  const { error } = useNotifications();

  const { isChatEnabled } = useChatModuleEnabled();

  // Prefetch calls that are blocking
  const { isLoading: isLoadingIntlMessages, messages, isError: intlError } = useInitializeLocales();
  const { isLoading: isLoadingEnabledModules, error: errorModules } = useEnabledModules();
  const { isLoading: isLoadingMe, error: errorMe } = useMe();
  const { isLoading: isLoadingUser, user, userId, error: errorUser } = useCurrentUser();
  const { isLoading: isLoadingMeasurementsToken } = useMeasurementsToken();
  // INFO: Fetch chat token only if at least one of the chat module is enabled
  const {
    isLoading: isLoadingChatUserToken,
    isFetching: isFetchingChatUserToken,
    error: chatUserTokenError,
  } = useChatUserToken({
    enabled: isChatEnabled,
  });

  const userWebLanguage = user?.webLanguage;

  // Prefetch calls that are non-blocking
  usePrefetchNonBlocking();

  const isLoading =
    !userId ||
    isLoadingEnabledModules ||
    isLoadingMe ||
    isLoadingUser ||
    isLoadingIntlMessages ||
    isLoadingMeasurementsToken ||
    ((isLoadingChatUserToken || isFetchingChatUserToken) && isChatEnabled);

  // Set Sentry user so we can track errors per user
  useEffect(() => {
    if (!userId) return;
    void digestMessage(userId).then((data) => setUser({ id: data }));
  }, [userId]);

  // Set locale based on the user's webLanguage
  useEffect(() => {
    // If we are loading the user or dont have a userId yet, dont run this
    if (isLoadingUser || !userId) return;
    // If the users locale is valid set it else use the default
    const localeIsValid = userWebLanguage && locales.includes(userWebLanguage);
    const newLocale = localeIsValid ? userWebLanguage : defaultLocale;
    setLocale(newLocale);
  }, [isLoadingUser, userId, userWebLanguage, defaultLocale, locales, setLocale]);

  useEffect(() => {
    const checkErrors = async () => {
      if (!!errorModules || !!errorMe || !!errorUser) {
        // If we have an HTTP Error we did receive a response, we only want to show network error message
        // In case we do have some kind of other network / connection related error
        const hasNetworkError =
          !(errorModules instanceof HTTPError) &&
          !(errorMe instanceof HTTPError) &&
          !(errorUser instanceof HTTPError) &&
          !(chatUserTokenError instanceof HTTPError);

        if (hasNetworkError) {
          // if the error is caused by bad/absent internet, we don't logout the user but instead show a toast
          // we try to localize the message, but if intl wasn't loaded yet, we just show an English message
          const title =
            (messages?.[locale]?.messages?.core_general_alert_error_title_connection as string) ||
            'Network error';
          const message =
            (messages?.[locale]?.messages?.core_general_alert_error_body_connection as string) ||
            'Please check your internet connection and refresh the page';

          error({ title, message });
          return;
        }

        if (errorModules) captureException(errorModules);
        if (errorMe) captureException(errorMe);
        if (errorUser) captureException(errorUser);

        await flush(2000);
        await logOut();
      }
    };

    void checkErrors();
  }, [errorModules, chatUserTokenError, errorMe, errorUser, locale, logOut, error, messages]);

  if (intlError) {
    throw new Error('Something went wrong while loading the translations');
  }

  if (isLoading) return null;

  return children;
};

export default PrefetchGate;
