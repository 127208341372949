import { useCallback } from 'react';

import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import type { FeatureFlags } from 'types/features.types';

const flagEnabled = (features: Record<string, boolean> | undefined, flag: string) =>
  !!(features && flag in features && features[flag]);

export const useFeatureFlag = (flag: FeatureFlags) => {
  const features = useTenantConfig()?.config?.features;
  return flagEnabled(features, flag);
};

export const useCheckFeatureFlag = () => {
  const features = useTenantConfig()?.config?.features;
  return useCallback((flag: FeatureFlags) => flagEnabled(features, flag), [features]);
};
