import { useCallback, useEffect, useRef } from 'react';

import { useActiveModule } from 'hooks/useActiveModule';
import { useMeasurements } from 'services/measurements';
import { MeasurementEntity, MeasurementEventName } from 'types/measurement.types';

export const useTrackModuleView = () => {
  const currentModuleId = useRef<string>();
  const sendEvent = useMeasurements();
  const moduleId = useActiveModule();

  useEffect(() => {
    // page visit event
    const lastModuleId = currentModuleId.current;
    currentModuleId.current = moduleId;

    if (moduleId && lastModuleId !== moduleId) {
      sendEvent({
        entity: MeasurementEntity.Module,
        eventName: MeasurementEventName.Show,
        data: { moduleId },
      });
    }
  }, [moduleId, sendEvent]);

  const onModuleInteraction = useCallback(() => {
    if (!currentModuleId.current) return;

    sendEvent({
      entity: MeasurementEntity.Module,
      eventName: MeasurementEventName.Interaction,
      data: { moduleId: currentModuleId.current },
    });
  }, [sendEvent]);

  return { onModuleInteraction };
};
