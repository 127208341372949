/**
 * typeguard for undefined values in array --> https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
 */
export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
};

// Wait for a boolean callback to be come true, with an interval of 50ms
export const waitFor = (callback: () => boolean) => {
  const result = callback();
  if (result) return Promise.resolve(result);

  return new Promise<boolean>((resolve) => {
    const result = callback();
    if (result) {
      resolve(result);
    } else {
      const handler = window.setInterval(() => {
        const result = callback();
        if (result) {
          window.clearInterval(handler);
          resolve(result);
        }
      }, 50);
    }
  });
};
