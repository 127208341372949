import type { Links, Pagination } from 'types/api.types';

const LIMIT = 100;

type MetaLinks = {
  links?: Links;
};

/**
 * Allows to easily fetch all the queries going through all the next pages one by one
 * This is not the most performant way but its better than anything else.
 * If we had proper pagination we could easily loop through it
 */
export const fetchAllNextQueries = async <
  TQueryFn extends (args: Pagination) => Promise<Awaited<ReturnType<TQueryFn>> & MetaLinks>,
  TQueryData extends MetaLinks = Awaited<ReturnType<TQueryFn>>,
  TQueryDataKey extends keyof TQueryData = keyof TQueryData,
>(
  queryFunc: TQueryFn,
  { groupBy, perPage = LIMIT }: { groupBy: TQueryDataKey; perPage?: number },
): Promise<TQueryData[TQueryDataKey]> => {
  const firstRequest = await queryFunc({ p: 1, limit: perPage });
  const result = [] as Array<TQueryData>;

  let nextLink = firstRequest?.links?.next;

  while (nextLink) {
    const nextPage = new URL(nextLink).searchParams.get('p');
    const resp = await queryFunc({
      p: Number(nextPage),
      limit: perPage,
    });
    nextLink = resp?.links?.next;
    result.push(resp);
  }

  const groupedData = result.reduce<TQueryData>(
    (memo, curr) => ({
      ...memo,
      [groupBy]: [memo[groupBy], curr[groupBy]].flat(1),
    }),
    firstRequest,
  );

  return groupedData[groupBy];
};
