import { Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { isNative } from 'utils/capacitor.utils';

import { useLocales } from 'queries/tenants/useLocales';
import { useCurrentUser } from 'queries/users/useCurrentUser';
import { useUserUpdate } from 'queries/users/useUserUpdate';
import { getLocaleName } from 'services/i18n/utils.i18n';
import { useSpencerStore } from 'store/spencer';

import CookieBanner from 'components/@onboarding/CookieBanner';
import OnboardingStep from 'components/@onboarding/OnboardingStep';
import { type OnboardingProps, OnboardingSteps } from 'components/@onboarding/types';

const LocaleCountryMapping: Record<string, string> = {
  'bg-BG': 'bg',
  'de-DE': 'de',
  'en-US': 'us',
  'es-ES': 'es',
  'fr-BE': 'fr',
  'hr-HR': 'hr',
  'hu-HU': 'hu',
  'it-IT': 'it',
  'mr-IN': 'in',
  'nb-NO': 'no',
  'nl-BE': 'nl',
  'pl-PL': 'pl',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'sl-SI': 'si',
  'tr-TR': 'tr',
  'uk-UA': 'ua',
  'zh-CN': 'cn',
};

const SelectLanguage = ({ setActiveStep, skipPrivacyStep }: OnboardingProps) => {
  const { setLocale } = useSpencerStore((state) => state.actions);
  const { updateUser } = useUserUpdate();
  const { userId, serviceId } = useCurrentUser();
  const { locales } = useLocales();

  const handleSelectLanguage = (locale: string) => {
    setLocale(locale);
    updateUser({ userId, serviceId, data: { webLanguage: locale } });

    if (skipPrivacyStep) {
      setActiveStep(OnboardingSteps.selectTopics);
    } else {
      setActiveStep(OnboardingSteps.privacy);
    }
  };

  return (
    <OnboardingStep
      title={<FormattedMessage id="module_web_onboarding_language" />}
      content={
        <Stack pt={1} alignItems="flex-start" gap={{ xs: 3, lg: 2 }}>
          {locales.map((locale) => (
            <Button
              key={locale}
              startIcon={
                <img
                  src={`https://flagcdn.com/h40/${LocaleCountryMapping[locale]}.webp`}
                  width="24"
                  height="24"
                  alt={locale}
                  style={{ borderRadius: '50%' }}
                />
              }
              variant="text"
              fullWidth
              sx={(theme) => ({
                justifyContent: 'flex-start',
                textAlign: 'left',
                padding: theme.spacing(1, 2),
                color: theme.palette.neutral01[600],
                borderRadius: '20px',
                width: 'fit-content',
              })}
              onClick={() => handleSelectLanguage(locale)}
            >
              <FormattedMessage id={getLocaleName(locale)} />
            </Button>
          ))}
        </Stack>
      }
      banner={!isNative && <CookieBanner />}
    />
  );
};

export default SelectLanguage;
