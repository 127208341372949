import { useQuery } from '@tanstack/react-query';

import { latestBundleQuery } from './queries';

export const useLatestBundle = () => {
  const query = useQuery({
    ...latestBundleQuery,
  });

  return {
    latestBundle: query.data,
    isLoading: query.isLoading || query.isFetching,
    refetch: query.refetch,
  };
};
