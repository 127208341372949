import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { isDev, sentryRelease } from 'utils/env.utils';

Sentry.init(
  {
    enabled: !isDev,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: sentryRelease,
    dist: import.meta.env.VITE_BUILD_NUMBER,
    tracesSampleRate: 0.05,
    integration: [
      SentryReact.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    ignoreErrors: ['window.crypto.randomUUID is not a function'],
  },
  SentryReact.init,
);

export const sentryCreateBrowserRouter = SentryReact.wrapCreateBrowserRouterV7(createBrowserRouter);
