import type { Href } from './api.types';

export enum LinkType {
  WebLink = 'web_link',
  MobileApplication = 'mobile_application',
  DesktopApplication = 'desktop_application',
  Form = 'form',
  FileLink = 'file_link',
}

export interface Link {
  id: number;
  image: Href;
  name: string;
  openExternal: boolean;
  type: LinkType;
  webUrl: string;
  formPublication?: Href;
  storageFile?: Href;
}

export type LinkParams = {
  featured?: boolean;
  p?: number;
  limit?: number;
  type: LinkType | Array<LinkType>;
};
