export const isDev = import.meta.env.DEV;
export const isProd = import.meta.env.MODE === 'production';
export const isStag = import.meta.env.MODE === 'staging';
export const APP_VERSION = import.meta.env.VITE_VERSION;
export const BUILD_HASH = import.meta.env.VITE_BUILD_HASH;
export const SPENCER_STORE_LOCAL_STORAGE_KEY = `spencer-app-cache:${import.meta.env.MODE}`;
export const RQ_CACHE_KEY = `spencer-app-query-cache:${import.meta.env.MODE}`;
export const RQ_CACHE_BUSTER = `${import.meta.env.MODE}@${import.meta.env.VITE_BUILD_HASH}`;

export const sentryRelease = isProd
  ? `${import.meta.env.VITE_PACKAGE_NAME}@${import.meta.env.VITE_VERSION}`
  : `${import.meta.env.VITE_PACKAGE_NAME}@${import.meta.env.VITE_VERSION}-${import.meta.env.MODE}`;

export const DEFAULT_LOCALE = 'en-US';

// Only used in dev mode
export const DEV_BASE_URL = import.meta.env.VITE_DEV_BASE_URL;
