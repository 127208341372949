import { isNative } from 'utils/capacitor.utils';

import { useIsModuleEnabled } from 'hooks/useIsModuleEnabled';
import { useFeatureFlag } from 'queries/configuration/useFeatureFlag';
import { Modules } from 'types/module.types';

// Universal hook to determine if chat module is enabled
export const useChatModuleEnabled = () => {
  const isModuleChatEnabled = useIsModuleEnabled(Modules.Chat);
  const isChatMobileAvailable = useFeatureFlag('chat_mobile');
  const isChatWebAvailable = useFeatureFlag('chat_web');

  // INFO: Determine if chat is enabled based on platform and tenant configuration
  // We have two protection layers modules and retool tenant configuration
  const isChatEnabled = Boolean(
    isModuleChatEnabled && (isNative ? isChatMobileAvailable : isChatWebAvailable),
  );
  return {
    isChatEnabled,
  };
};
