import { useCallback } from 'react';

import { useEnabledModules } from 'queries/configuration/useEnabledModules';
import { useCheckFeatureFlag } from 'queries/configuration/useFeatureFlag';
import { useMe } from 'queries/configuration/useMe';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import type { FeatureFlags } from 'types/features.types';
import { Modules } from 'types/module.types';

const CORE_MODULES: Array<Modules> = [
  Modules.Settings,
  Modules.Search,
  Modules.UserDetail,
  Modules.Bookmarks,
];

export function useIsModuleEnabled(moduleId: Modules): boolean;
export function useIsModuleEnabled(): (moduleId: Modules | undefined) => boolean;
export function useIsModuleEnabled(moduleId?: Modules) {
  const { data: enabledModules } = useEnabledModules();
  const { data: meData } = useMe();
  const restrictedModules = meData?.restrictedModules;
  const checkFeatureFlag = useCheckFeatureFlag();
  const features = useTenantConfig()?.config?.features;
  const featureKeys = Object.keys(features || {});

  const checkModuleEnabled = useCallback(
    (moduleId: Modules | undefined) => {
      if (!moduleId) return true;
      if (CORE_MODULES.includes(moduleId)) return true;
      const isModuleEnabled = enabledModules?.includes(moduleId);
      const isNotRestricted = !restrictedModules?.includes(moduleId);

      const featureKey = featureKeys.find((key) => key.toLowerCase() === moduleId.toLowerCase());

      const isFeatureEnabled = featureKey
        ? checkFeatureFlag(moduleId?.toLowerCase() as FeatureFlags)
        : true;

      return isModuleEnabled && isNotRestricted && isFeatureEnabled;
    },
    [checkFeatureFlag, enabledModules, featureKeys, restrictedModules],
  );

  if (moduleId) return checkModuleEnabled(moduleId);
  return checkModuleEnabled;
}
