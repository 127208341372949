import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'queries/users/useCurrentUser';
import { useSelectedLocale } from 'services/i18n';

import { newsTopicsQuery } from './queries';

export const useNewsTopics = () => {
  const { locale } = useSelectedLocale();
  const { userId } = useCurrentUser();

  const query = useQuery({
    ...newsTopicsQuery(locale, userId),
    meta: { store: true },
  });

  return {
    data: query.data?.data ?? [],
    isLoading: query.isLoading,
  };
};
