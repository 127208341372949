import { Keyboard } from '@capacitor/keyboard';

import { isNative } from 'utils/capacitor.utils';

import { useSpencerStore } from 'store/spencer';

if (isNative) {
  Keyboard.addListener('keyboardWillShow', () =>
    useSpencerStore.getState().actions.setKeyboardActive(true),
  );

  Keyboard.addListener('keyboardWillHide', () =>
    useSpencerStore.getState().actions.setKeyboardActive(false),
  );
}
