import type { ReactNode } from 'react';

import { TenantUpdateContext } from './context';

interface Props {
  children: ReactNode;
}

// TODO: JS this is not working obviously, this need to be implemented differently
// We probably want to keep track of changes and set specific state for it
// We can also use react-query for this instead of writing glue around this

const TenantUpdateProvider = ({ children }: Props) => {
  // const [hasUpdates, setHasUpdates] = useState(false);
  // const { data: initialTenant } = useTenantConfig();

  // const { data: tenant } = useTenantConfigInterval({
  //   // Disabled for now
  //   refetchInterval: hasUpdates || isNative ? false : false,
  //   initialData: initialTenant,
  // });

  // useEffect(() => {
  //   if (!config.TENANT_CONFIG || hasUpdates || !tenant) return;

  //   if (!isEqual(tenant, config.TENANT_CONFIG)) {
  //     setHasUpdates(true);
  //   }
  // }, [hasUpdates, tenant]);

  // const contextValue = useMemo(
  //   () => ({ hasUpdates, reload: window.location.reload }),
  //   [hasUpdates],
  // );

  return (
    <TenantUpdateContext.Provider value={{ hasUpdates: false, reload: () => undefined }}>
      {children}
    </TenantUpdateContext.Provider>
  );
};

export default TenantUpdateProvider;
