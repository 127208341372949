import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'queries/users/useCurrentUser';

import { userTopicIdsQuery } from './queries';

export const useUserTopicIds = () => {
  const { userId } = useCurrentUser();

  const query = useQuery({
    ...userTopicIdsQuery(userId),
  });

  return {
    data: query.data ?? [],
    isLoading: query.isLoading,
  };
};
