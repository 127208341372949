import { useMemo } from 'react';

import { useMedia } from 'hooks/useMedia';

import { useTenantConfig } from './useTenantConfig';

export const useZoomFactor = () => {
  const { isDesktop } = useMedia();
  const zoom = useTenantConfig()?.config?.zoom;
  return useMemo(() => {
    if (!isDesktop) return undefined;
    if (!zoom || zoom === '0') return undefined;
    if (zoom === '1') return undefined;
    return zoom;
  }, [isDesktop, zoom]);
};
