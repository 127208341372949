import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { type NotificationPayload, NotificationTypes } from 'types/snackbar.types';

import Notification from './Notification';

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  const add = useCallback(
    (notification: NotificationPayload) => {
      const key = (notification.title ?? '') + notification.message;

      return enqueueSnackbar(<Notification {...notification} notificationKey={key} />, { key });
    },
    [enqueueSnackbar],
  );

  const success = useCallback(
    (notification: Omit<NotificationPayload, 'type'>) =>
      add({ ...notification, type: NotificationTypes.Success }),
    [add],
  );

  const info = useCallback(
    (notification: Omit<NotificationPayload, 'type'>) =>
      add({ ...notification, type: NotificationTypes.Info }),
    [add],
  );

  const error = useCallback(
    (notification: Omit<NotificationPayload, 'type'>) =>
      add({ ...notification, type: NotificationTypes.Error }),
    [add],
  );

  const warning = useCallback(
    (notification: Omit<NotificationPayload, 'type'>) =>
      add({ ...notification, type: NotificationTypes.Warning }),
    [add],
  );

  return {
    success,
    info,
    error,
    warning,
  };
};
