import type { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginLeft: 0,
      marginRight: 0,

      [theme.breakpoints.up('lg')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '@media print': {
          maxWidth: '100%',
        },
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },

      [theme.breakpoints.between('xs', 'lg')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    }),
  },
};
