import { useQuery } from '@tanstack/react-query';

import type { User } from 'types/user.types';

import { userQuery } from './queries';

export const useUser = (
  userId: User['spencerUuid'] | undefined,
  config: Partial<ReturnType<typeof userQuery>> = {},
) => {
  const query = useQuery({
    ...userQuery(userId ?? ''),
    enabled: !!userId,
    ...config,
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};
