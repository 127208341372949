import type { HTTPError } from 'ky';

import { IGNORE_STATUS_CODES } from 'constants/error.constants';

// List of status codes that we don't want to log to Sentry or show to the user
export const expectedError = (error: HTTPError) => {
  if (!error.response?.status) return false;

  return IGNORE_STATUS_CODES.includes(error.response?.status);
};

export const scrollToId = (id: string, options: ScrollIntoViewOptions = { behavior: 'smooth' }) => {
  const el = document.getElementById(id);
  if (el) el.scrollIntoView(options);
};

export const isTouchscreenDevice = 'ontouchstart' in document.documentElement;

export const hasNavigatorApi = (api: string) =>
  typeof navigator !== 'undefined' && api in navigator;
