import type { Components, Theme } from '@mui/material';

export const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(3),
      },
    }),
  },
};
