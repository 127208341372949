import { SnackbarProvider as NotistackProvider } from 'notistack';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  return (
    <NotistackProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={1}
      autoHideDuration={5000}
      preventDuplicate
      domRoot={document.getElementById('snackbar-root') ?? undefined}
      style={{
        boxShadow: 'none',
        backgroundColor: 'unset',
        marginBottom: 'calc(32px + var(--safe-area-inset-bottom, 0px))',
        marginRight: -24,
        marginLeft: -8,
        paddingLeft: 0,
      }}
      dense
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
