import { queryOptions } from '@tanstack/react-query';

import { fetchAllNextQueries } from 'utils/api.utils';
import { notEmpty } from 'utils/typescript.utils';

import { Duration } from 'queries/constants';
import { resolveHrefsQueries, resolveHrefUuid } from 'queries/utils';
import { apiClient } from 'services/api/client';
import { queryClient } from 'services/react-query';
import type { Collection, Href } from 'types/api.types';
import type { Topic } from 'types/topics.types';

const KEY_PREFIX = 'topics';

// There is no locale in this query key as we only fetch the id's which don't contain localized
// information
export const userTopicIdsQuery = (userId: string) =>
  queryOptions({
    queryKey: [KEY_PREFIX, 'user', userId],
    queryFn: async () => {
      const data = await fetchAllNextQueries(
        async (nextParams) =>
          apiClient
            .get(`api/users/${userId}/news/topic-subscriptions`, {
              searchParams: { ...nextParams },
            })
            .json<Collection<Array<Href>>>(),
        { groupBy: 'data' },
      );

      return (data ?? []).map(resolveHrefUuid).filter(notEmpty);
    },
    staleTime: Duration.FOREVER,
  });

const newsTopicQuery = (locale: string, topicId: string) =>
  queryOptions({
    queryKey: [KEY_PREFIX, 'detail', topicId, locale],
    queryFn: async () => {
      const response = await apiClient.get(`api/news/topics/${topicId}`).json<Topic>();
      return { ...response, id: response.id.toString() };
    },
    enabled: !!topicId,
    staleTime: Duration.FOREVER,
  });

const selectTopics = (data: Collection<Array<Topic>>) => ({
  ...data,
  data: data.data?.sort((a, b) => (a.position > b.position ? 1 : -1)),
});

export const newsTopicsQuery = (locale: string, userId: string) =>
  queryOptions({
    queryKey: [KEY_PREFIX, 'news', userId, locale],
    queryFn: async () => {
      const data = await fetchAllNextQueries(
        async (nextParams) =>
          apiClient
            .get(`api/users/${userId}/news/topics`, {
              searchParams: { ...nextParams },
            })
            .json<Collection<Array<Href>>>(),
        { groupBy: 'data' },
      );

      return resolveHrefsQueries({ data }, ({ id }) =>
        queryClient.ensureQueryData(newsTopicQuery(locale, id ?? '')),
      );
    },
    select: selectTopics,
    staleTime: Duration.FIFTEEN_MIN,
  });
