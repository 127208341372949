import { queryOptions } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { apiClient } from 'services/api/client';
import type { AppConfigurationResponse, MeResponse } from 'types/configuration.types';

const PREFIX_KEY = 'configuration';

export const meQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'me'],
  queryFn: () => apiClient.get('api/me').json<MeResponse>(),
  staleTime: Duration.ONE_HOUR,
});

export const enabledModulesQuery = queryOptions({
  queryKey: [PREFIX_KEY, 'enabled-modules'],
  queryFn: () => apiClient.get('api/configuration').json<AppConfigurationResponse>(),
  staleTime: Duration.ONE_HOUR,
});
