import { queryOptions } from '@tanstack/react-query';

import { Duration } from 'queries/constants';
import { apiClient } from 'services/api/client';
import type { WorkeloMagicLink } from 'types/api.types';

const KEY_PREFIX = 'workelo';

export const workeloMagicLinkQuery = queryOptions({
  queryKey: [KEY_PREFIX, 'magic-link'],
  queryFn: () => apiClient.get(`api/workelo/magic-link`).json<WorkeloMagicLink>(),
  staleTime: Duration.ONE_HOUR,
});
