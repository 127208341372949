import { alpha, ThemeProvider as MuiThemeProvider } from '@mui/material';
import type { PaletteOptions } from '@mui/material/styles';
import _merge from 'lodash-es/merge';
import _set from 'lodash-es/set';
import { type ReactNode, useState } from 'react';

import { useTenantConfig } from 'queries/tenants/useTenantConfig';
import type { TenantTheme } from 'types/env.types';

import defaultTheme from './theme';

interface Props {
  children: ReactNode;
}

const alphaColor = (color: string) => (color ? alpha(color, 0.1) : color);

const compactObject = (
  data: string | Record<string, string | Record<string, string>>,
): string | Record<string, string> => {
  if (typeof data !== 'object') return data;

  return Object.keys(data).reduce((accumulator, key) => {
    const isObject = typeof data[key] === 'object';
    const value = isObject ? compactObject(data[key]) : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (value === undefined || isEmptyObject || value === '') {
      return accumulator;
    }

    return Object.assign(accumulator, { [key]: value });
  }, {});
};

const createBrandedPalette = (theme: TenantTheme | undefined) => {
  if (!theme) return {};

  const palette: Omit<PaletteOptions, 'reaction' | 'neutral01' | 'text'> = {
    // main
    primary: {
      main: theme.main?.[100],
      light: alphaColor(theme.main?.[100]),
    },
    // Statuses
    error: {
      main: theme.status?.error?.[100],
      light: alphaColor(theme.status?.error?.[100]),
      contrastText: theme.status?.error?.invert,
    },
    success: {
      main: theme.status?.success?.[100],
      light: alphaColor(theme.status?.success?.[100]),
      contrastText: theme.status?.success?.invert,
    },
    warning: {
      main: theme.status?.warning?.[100],
      light: alphaColor(theme.status?.warning?.[100]),
      contrastText: theme.status?.warning?.invert,
    },
    // brand
    brand: theme,
  };

  return {
    // @ts-expect-error dont care about this
    palette: compactObject(palette),
  };
};

// this Provider can also be wrapped around the <Splash> component in TenantSetup if the splash screen needs to use theme colors on its exit animation
const ThemeProvider = ({ children }: Props) => {
  const tenantTheme = useTenantConfig().config?.theme;
  const [theme] = useState(() => _merge(defaultTheme, createBrandedPalette(tenantTheme)));

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
