import { setTag } from '@sentry/capacitor';
import camelcaseKeys from 'camelcase-keys';
import decamelizeKeys from 'decamelize-keys';
import ky, { type Input, type Options } from 'ky';
import { v4 } from 'uuid';

import { isDev, isStag } from 'utils/env.utils';
import { getPlatformName } from 'utils/notifications.utils';

import { getAccessToken } from 'services/identity';
import { getTenantConfig } from 'services/tenant/config';
import { useSpencerStore } from 'store/spencer';

type ObjectOrArray = Record<string, unknown> | Array<unknown>;

enum SpencerHeaders {
  Os = 'x-spencer-os',
  RequestId = 'x-spencer-request-id',
  Language = 'Accept-Language',
  Authorization = 'Authorization',
}

const internalApiClient = ky.create({
  headers: {
    'Content-Type': 'application/json',
  },
  parseJson: (body) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    camelcaseKeys(JSON.parse(body), {
      deep: true,
      exclude: [/^property[0-9a-z]/, /^([a-z]{2})_([A-Z]{2})$/],
    }),
  stringifyJson: (body) =>
    JSON.stringify(
      decamelizeKeys(body as ObjectOrArray, {
        deep: true,
        exclude: [/^property[0-9a-z]/, /^([a-z]{2})_([A-Z]{2})$/],
      }),
    ),
  hooks: {
    beforeRequest: [
      async (request) => {
        const requestId = v4();

        request.headers.set(SpencerHeaders.Os, getPlatformName('windows'));
        request.headers.set(SpencerHeaders.RequestId, requestId);

        const locale = useSpencerStore.getState().locale;
        request.headers.set(SpencerHeaders.Language, locale.toUpperCase());

        const accessToken = await getAccessToken();
        if (accessToken) {
          request.headers.set(SpencerHeaders.Authorization, `Bearer ${accessToken}`);
        }
        setTag(SpencerHeaders.RequestId, requestId);
      },
    ],

    beforeError: [
      (error) => {
        if (isDev || isStag) console.error(error);
        return error;
      },
    ],
  },
});

// Allows to inject at runtime the tenant config without always returning a new instance
// Creating instances for every request would be expensive, so rather we proxy the api client
// and add the prefixUrl in the proxy
export const apiClient = new Proxy(internalApiClient, {
  // Use case `apiClient(...)` this is a shortcut for a get call
  apply: (target, _thisArg, argArray) => {
    const url = argArray[0];
    const options = argArray[1];
    const prefixUrl = getTenantConfig()?.apihost;
    return target(url, { ...options, prefixUrl: `https://${prefixUrl}` });
  },
  // Use case apiClient.get(...) or any other api method
  get: (obj, prop) => {
    if (
      prop === 'get' ||
      prop === 'post' ||
      prop === 'put' ||
      prop === 'delete' ||
      prop === 'patch' ||
      prop === 'head'
    ) {
      const receiver = Reflect.get(obj, prop);
      const prefixUrl = getTenantConfig()?.apihost;
      return (url: Input, options?: Options) =>
        receiver.apply(obj, [url, { prefixUrl: `https://${prefixUrl}`, ...options }]);
    }
  },
});

// Removes empty values and sorts the object by key to ensure stable query keys
export const cleanParams = <T extends Record<string, Array<string> | string | number | boolean>>(
  dirtyParams?: T,
) =>
  Object.fromEntries(
    Object.entries(dirtyParams ?? {})
      .reduce<Array<[string, string | number | boolean]>>((acc, [key, value]) => {
        if (value === '' || value === undefined || value === null) return acc;
        if (Array.isArray(value)) {
          acc.push([key, value.join(',')]);
        } else {
          acc.push([key, value]);
        }
        return acc;
      }, [])
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
  );
