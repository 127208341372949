import type { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.Mui-disabled': {
        backgroundColor: theme.palette.brand.main[100],
        color: theme.palette.brand.main.invert,
      },
    }),
    icon: {
      marginLeft: 0,
      root: {
        cursor: 'inherit',
      },
    },
    label: {
      fontSize: '12.8px',
      lineHeight: '16px',
      whiteSpace: 'normal',
      fontWeight: 700,
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
    },
  },
};
