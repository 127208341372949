import { lazy, type ReactNode } from 'react';

import { isNative } from 'utils/capacitor.utils';

// We lazy load this chunk because it's only needed for native platforms (reduces bundle size for web)
const NotificationProvider = lazy(
  () =>
    import(
      /* webpackChunkName: NativePushNotificationProvider */ 'services/push-notifications/NotificationProvider'
    ),
);
const WebNotificationProvider = lazy(
  () =>
    import(
      /* webpackChunkName: WebPushNotificationProvider */ 'services/push-notifications/WebNotificationProvider'
    ),
);

interface Props {
  children: ReactNode;
}

const PushNotificationWrapper = isNative ? NotificationProvider : WebNotificationProvider;

const PushNotificationProvider = ({ children }: Props) => {
  return <PushNotificationWrapper>{children}</PushNotificationWrapper>;
};

export default PushNotificationProvider;
