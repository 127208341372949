import { useQuery } from '@tanstack/react-query';

import { Duration } from 'queries/constants';

import { meQuery } from './queries';

export const useMe = () => {
  const query = useQuery({
    ...meQuery,
    staleTime: Duration.ONE_HOUR,
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
  };
};
