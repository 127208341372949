import { InteractionStatus, InteractionType, type RedirectRequest } from '@azure/msal-browser';
import { type AccountIdentifiers, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useRef } from 'react';

import { useSpencerStore } from 'store/spencer';

interface InteractionRequests {
  redirect?: RedirectRequest;
}

/**
 * Inspired by the original useMsalAuthentication hook
 * We only deal with actual login in and not fetching tokens as we only need to fetch a token
 * When we perform an API call (which happens instantly in the next component in the tree)
 * We also use our global state mangement as a single source of throught prevent any race conditions
 */
export const useMsalLogin = (
  interactionType: InteractionType,
  interactionRequests: RedirectRequest,
  accountIdentifiers?: AccountIdentifiers,
) => {
  const { instance, inProgress, logger } = useMsal();
  const isAuthenticated = useIsAuthenticated(accountIdentifiers);
  const authError = useSpencerStore((state) => state.auth.error);

  // Flag used to control when the hook calls login
  const shouldLogin = useRef(true);
  useEffect(() => {
    if (authError) {
      // Errors should be handled so we can show proper ui
      shouldLogin.current = false;
      return;
    }
  }, [authError, isAuthenticated]);

  const loginRedirect = useCallback(
    async (callbackInteractionRequests?: InteractionRequests['redirect']) => {
      // Now do the login redirect
      logger.verbose('useMsalLogin - Calling loginRedirect');
      const request = callbackInteractionRequests || interactionRequests;
      await instance.loginRedirect(request);

      // This never happens as we are navigated away from the page
      return null;
    },
    [logger, interactionRequests, instance],
  );

  useEffect(() => {
    // Ensure we don't try to login when another event is in progress
    if (
      shouldLogin.current &&
      inProgress === InteractionStatus.None &&
      interactionType !== InteractionType.None &&
      !isAuthenticated
    ) {
      // Disable future logins, since we do a redirect the next time we come back this
      // will be evaluated again
      shouldLogin.current = false;
      logger.info('useMsalLogin - No user is authenticated, attempting to login');
      loginRedirect().catch(() => {
        // dont care about this as we have global state auth errors
        // Do capture it so we dont crash the app
      });
    }
  }, [isAuthenticated, inProgress, loginRedirect, logger, interactionType]);

  return {
    retryLogin: loginRedirect,
  };
};
