import { useInfiniteQuery } from '@tanstack/react-query';

import { useSelectedLocale } from 'services/i18n';
import type { LinkParams } from 'types/links.types';

import { infiniteLinksQuery } from './queries';

export const useInfiniteLinks = (params: LinkParams) => {
  const { locale } = useSelectedLocale();
  const query = useInfiniteQuery({
    ...infiniteLinksQuery(locale, params),
  });

  return {
    data: query.data?.pages.flatMap((page) => page.data ?? []) ?? [],
    fetchNextPage: query.fetchNextPage,
    isFetchingNextPage: query.isFetchingNextPage,
    hasNextPage: query.hasNextPage,
    isLoading: query.isLoading,
  };
};
