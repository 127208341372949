import type { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltipPlacementLeft: {
      marginRight: '5px !important',
    },
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.neutral01[700],
      borderRadius: theme.shape.borderRadius * 4,
      display: 'flex',

      ...theme.typography.caption,
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.neutral01[700],
      width: 12,
      height: 6,
    }),
    popper: {
      '&[data-popper-placement*="top"] .MuiTooltip-arrow': { marginBottom: '-6px' },
    },
  },
};
