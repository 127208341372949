import { useQuery } from '@tanstack/react-query';

import { tenantConfigQuery } from './queries';

export const useTenantConfig = () => {
  const query = useQuery({
    ...tenantConfigQuery,
  });

  return {
    config: query.data,
    isLoading: query.isLoading,
    error: query.error,
  };
};
