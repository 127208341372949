import { Navigate, type NonIndexRouteObject, Outlet, type RouteObject } from 'react-router-dom';
import Root from 'Root';

import { safeLazy } from 'utils/routing.utils';

import { Routes } from 'constants/routes.constants';
import { tenantConfigQuery } from 'queries/tenants/queries';
import { queryClient } from 'services/react-query';
import { Modules } from 'types/module.types';
import type { ReactQueryLoader } from 'types/routes.types';

import RouteBoundary from 'components/@boundaries/RouteBoundary';
import { iframeModules } from 'components/@navigations/config';
import RouteLoadingState from 'components/@states/RouteLoadingState';

const extractDefaultExport = <
  T extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: (_props: any) => React.JSX.Element | null;
    loader?: ReactQueryLoader;
  },
>(
  filePromise: () => Promise<T>,
) => {
  return async () => {
    const { default: Component, loader } = await filePromise();
    // Ensures that every loader always has our tenant config loaded
    await queryClient.ensureQueryData(tenantConfigQuery);
    return { Component, loader: loader?.(queryClient) };
  };
};

export const rootRoute: NonIndexRouteObject & { children: Array<RouteObject> } = {
  Component: Root,
  ErrorBoundary: RouteBoundary,
  HydrateFallback: RouteLoadingState,
  children: [
    // Not found routing
    {
      path: '*',
      lazy: extractDefaultExport(safeLazy(() => import('./pages/not-found/NotFoundPage'))),
    },
    {
      path: Routes.NotFound,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/not-found/NotFoundPage'))),
    },
    // News
    {
      handle: {
        moduleId: Modules.CompanyNews,
      },
      children: [
        {
          Component: Outlet,
          children: [
            {
              path: Routes.News,
              lazy: extractDefaultExport(safeLazy(() => import('./pages/news/NewsOverviewPage'))),
            },
            {
              path: Routes.NewsDetail,
              lazy: extractDefaultExport(safeLazy(() => import('./pages/news/NewsDetailPage'))),
            },
            {
              path: Routes.NewsDetailPreview,
              lazy: extractDefaultExport(safeLazy(() => import('./pages/news/NewsPreviewPage'))),
              handle: '',
            },
          ],
        },
      ],
    },
    // Documents
    {
      handle: {
        moduleId: Modules.DocumentLibrary,
      },
      children: [
        {
          Component: Outlet,
          children: [
            {
              path: Routes.Documents,
              lazy: extractDefaultExport(
                safeLazy(() => import('./pages/documents/DocumentsOverviewPage')),
              ),
            },
            {
              path: Routes.DocumentsFolder,
              lazy: extractDefaultExport(
                safeLazy(() => import('./pages/documents/DocumentsFolderPage')),
              ),
            },
            {
              path: Routes.DocumentsDetail,
              lazy: extractDefaultExport(
                safeLazy(() => import('./pages/documents/DocumentsDetailPage')),
              ),
            },
            {
              path: Routes.DocumentsFavourites,
              lazy: extractDefaultExport(
                safeLazy(() => import('./pages/documents/DocumentsFavouritesPage')),
              ),
            },
          ],
        },
      ],
    },

    // Organisation
    {
      path: Routes.Organisation,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/organisation/OrganisationPage'))),
      children: [
        {
          path: Routes.People,
          lazy: extractDefaultExport(safeLazy(() => import('./pages/organisation/PeoplePage'))),
          handle: {
            moduleId: Modules.People,
          },
        },
        {
          path: Routes.Sites,
          lazy: extractDefaultExport(safeLazy(() => import('./pages/organisation/SitesPage'))),
          handle: {
            moduleId: Modules.Sites,
          },
        },
      ],
    },

    // Forms
    {
      children: [
        {
          path: Routes.Forms,
          lazy: extractDefaultExport(safeLazy(() => import('./pages/forms/FormsOverviewPage'))),
        },
        {
          path: Routes.FormsDetail,
          lazy: extractDefaultExport(safeLazy(() => import('./pages/forms/FormsDetailPage'))),
        },
      ],
      handle: {
        moduleId: Modules.Forms,
      },
    },

    // Surveys (edit)
    {
      path: Routes.SurveyEdit,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/surveys/SurveyEditPage'))),
      handle: {
        moduleId: Modules.Surveys,
      },
    },

    // Survey Details
    {
      children: [
        {
          path: Routes.SurveyDetail,
          lazy: extractDefaultExport(safeLazy(() => import('./pages/surveys/SurveyDetailPage'))),
          handle: {
            moduleId: Modules.Surveys,
          },
        },
      ],
    },

    // Links
    {
      path: Routes.Links,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/links/LinksOverviewPage'))),
      handle: {
        moduleId: Modules.LinkLibrary,
      },
    },

    // Settings
    {
      path: Routes.Settings,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/settings/SettingsPage'))),
      handle: {
        moduleId: Modules.Settings,
      },
    },
    // Profile
    {
      path: Routes.Profile,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/profile/ProfilePage'))),
      handle: {
        moduleId: Modules.UserDetail,
      },
    },
    // Sites
    {
      path: Routes.SitesDetail,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/sites/SitesDetailPage'))),
      handle: {
        moduleId: Modules.Sites,
      },
    },
    // Search
    {
      path: Routes.Search,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/search/SearchPage'))),
      handle: {
        moduleId: Modules.Search,
      },
    },
    // Tasks (delhaize)
    {
      path: Routes.Tasks,
      lazy: extractDefaultExport(safeLazy(() => import('./modules/tasks/tasksProvider'))),
      handle: {
        moduleId: Modules.Tasks,
      },
      children: [
        {
          path: Routes.Tasks,
          lazy: extractDefaultExport(safeLazy(() => import('./modules/tasks/pages/TaskList.page'))),
        },
        {
          path: Routes.TasksNew,
          lazy: extractDefaultExport(
            safeLazy(() => import('./modules/tasks/pages/TaskCreate.page')),
          ),
        },
        {
          path: Routes.TasksSearch,
          lazy: extractDefaultExport(
            safeLazy(() => import('./modules/tasks/pages/TaskSearch.page')),
          ),
        },
        {
          path: Routes.TasksFilter,
          lazy: extractDefaultExport(safeLazy(() => import('./modules/tasks/pages/TaskList.page'))),
        },
        {
          path: Routes.TasksCategory,
          lazy: extractDefaultExport(safeLazy(() => import('./modules/tasks/pages/TaskList.page'))),
        },
        {
          path: Routes.TasksDetails,
          lazy: extractDefaultExport(
            safeLazy(() => import('./modules/tasks/pages/TaskDetail.page')),
          ),
        },
      ],
    },
    // Mobile Menu
    {
      path: Routes.Menu,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/mobile-menu/MobileMenuPage'))),
    },
    // Bookmarks
    {
      path: Routes.Bookmarks,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/bookmarks/BookmarksPage'))),
      handle: {
        moduleId: Modules.Bookmarks,
      },
    },
    // Iframe embeds
    {
      path: Routes.IframeDetail,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/iframe/IframeDetailPage'))),
    },
    // Iframe modules
    ...iframeModules.map((iframeModule) => ({
      path: iframeModule.to,
      lazy: extractDefaultExport(safeLazy(() => import('./pages/iframe/IframeModulePage'))),
      id: iframeModule.moduleId,
      handle: {
        moduleId: iframeModule.moduleId,
      },
    })),
    // Chat does not have a top bar only a navigation / footer
    {
      children: [
        {
          path: Routes.Chat,
          lazy: extractDefaultExport(safeLazy(() => import('./pages/chat/ChatPage'))),
          handle: {
            moduleId: Modules.Chat,
          },
        },
      ],
    },
    // Redirects
    {
      path: '/',
      element: <Navigate to={Routes.News} replace />,
    },
  ],
};
