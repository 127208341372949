import { Box } from '@mui/material';
import type { ReactNode } from 'react';
import { ScrollRestoration } from 'react-router-dom';

import { isNative } from 'utils/capacitor.utils';

import { Routes } from 'constants/routes.constants';
import { useLoadingScreen } from 'hooks/useLoadingScreen';
import { useCurrentUser } from 'queries/users/useCurrentUser';

import ModuleProtected from 'components/@common/ModuleProtected';
import Onboarding from 'components/@onboarding/Onboarding';

import { useTrackModuleView } from './hooks';

interface Props {
  children: ReactNode;
}

/**
 * Every page is wrapped in the main layout, it ensures we correctly deal with
 * Scroll restoration and track our module views while also ensuring that we don't
 * Render any module which is not enabled
 * Keep this simple and clean
 */
const MainLayout = ({ children }: Props) => {
  const { onModuleInteraction } = useTrackModuleView();
  const { user } = useCurrentUser();
  useLoadingScreen({ spinner: false, splashScreen: false });

  // TODO: Is this the correct location? Maybe its better to redirect to /onboarding
  if (!user?.webOnboarded || (isNative && !user?.mobileOnboarded)) return <Onboarding />;

  return (
    <>
      <ScrollRestoration
        getKey={(location) => {
          if (location.pathname === Routes.News) return location.pathname as string;
          if (location.pathname === Routes.Search) return location.pathname as string;

          return location.key;
        }}
      />
      <Box display="flex" flexDirection="column" flex="1" onMouseUp={onModuleInteraction}>
        <ModuleProtected>{children}</ModuleProtected>
      </Box>
    </>
  );
};

export default MainLayout;
