import {
  type AuthenticationResult,
  type AuthError,
  BrowserAuthError,
  BrowserCacheLocation,
  type EventMessage,
  EventMessageUtils,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { captureException } from '@sentry/capacitor';
import memoize from 'lodash-es/memoize';

import { isNative } from 'utils/capacitor.utils';
import { DEFAULT_LOCALE } from 'utils/env.utils';
import { sharedPromisesCache } from 'utils/promises.utils';
import { waitFor } from 'utils/typescript.utils';

import { Routes } from 'constants/routes.constants';
import { localStoragePersister, queryClient } from 'services/react-query';
import { getTenantConfig } from 'services/tenant/config';
import { useSpencerStore } from 'store/spencer';
import type { TenantIdentityConfig, TenantIdentityRedirectUris } from 'types/env.types';

export const getB2cLocale = () => {
  const locale = useSpencerStore.getState().locale ?? DEFAULT_LOCALE;

  switch (locale) {
    case 'pt':
      return 'pt-pt';
    case 'zh':
      return 'zh-hans';
    default:
      return locale;
  }
};

const appInfo = isNative ? App.getInfo() : null;

const getClientId = (identityConfig: TenantIdentityConfig) =>
  isNative ? identityConfig.client_ids.capacitor : identityConfig.client_ids.web;

const getRedirectUri = (redirectUris: TenantIdentityRedirectUris, isBeta: boolean) => {
  const platform = Capacitor.getPlatform();

  if (platform !== 'android' && platform !== 'ios') return window.location.origin;
  if (isBeta) return redirectUris?.[platform]?.beta ?? '';
  return redirectUris?.[platform]?.release ?? '';
};

const getPostLogoutRedirectUri = (redirectUris: TenantIdentityRedirectUris, isBeta: boolean) => {
  const platform = Capacitor.getPlatform();

  if (platform !== 'android' && platform !== 'ios') return Routes.News;
  if (isBeta) return redirectUris?.[platform]?.beta ?? '';
  return redirectUris?.[platform]?.release ?? '';
};

const getMsalInstance = memoize(
  async (config: NonNullable<ReturnType<typeof getTenantConfig>>) => {
    const identityConfig = config?.identity;
    const identityRedirectUris = config?.mobile?.identityRedirectUris;
    // Note the last forward slash is important
    const authorityUrl = identityConfig?.issuer_url.replace('v2.0', '') ?? '';
    const appIdentifier = (await appInfo)?.id ?? '';
    const isBeta = appIdentifier.includes('beta');

    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: getClientId(identityConfig),
        authority: authorityUrl,
        knownAuthorities: [new URL(authorityUrl).hostname],
        redirectUri: getRedirectUri(identityRedirectUris, isBeta),
        postLogoutRedirectUri: getPostLogoutRedirectUri(identityRedirectUris, isBeta),
        ...(isNative
          ? {
              onRedirectNavigate: (url) => {
                Browser.open({
                  url,
                  windowName: 'Login',
                });
                return false;
              },
            }
          : {}),
      },
      system: {
        // This is needed to allow the login to work in an iframe
        // Attempt to fix sentry issue, github link: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2531
        //allowRedirectInIframe: true,
        allowNativeBroker: true,
        // loggerOptions: {
        //   logLevel: LogLevel.Info,
        //   loggerCallback: (level, message, _containsPii) => {
        //     // Only enable on staging for now
        //     if (isProd) return;
        //     switch (level) {
        //       case LogLevel.Error:
        //         console.error(message);
        //         return;
        //       case LogLevel.Info:
        //         console.info(message);
        //         return;
        //       case LogLevel.Verbose:
        //         console.info(message);
        //         return;
        //       case LogLevel.Warning:
        //         console.warn(message);
        //         return;
        //     }
        //   },
        //   piiLoggingEnabled: true,
        // },
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true,
      },
    });

    await msalInstance.initialize();

    // The tenantId is based on the authorityUrl
    const tenantId = authorityUrl?.split('/')?.at(-2);
    // Always set the first account as the active one
    const matchingAccount =
      msalInstance.getAccount({ tenantId }) || msalInstance.getAllAccounts()[0];
    if (matchingAccount) {
      msalInstance.setActiveAccount(matchingAccount);
    }

    // When logging in ensure to set the first active account
    msalInstance.addEventCallback((event: EventMessage) => {
      // Store the InProgress event in global state so we can use it outside of react context
      // Mainly used in the getAccessToken function
      const inProgressStatus = EventMessageUtils.getInteractionStatusFromEvent(event);
      if (inProgressStatus) {
        useSpencerStore.getState().actions.setAuthInProgress(inProgressStatus);
      }

      switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
          if (event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            // Clear errors on successful login
            useSpencerStore.getState().actions.setAuthError(null);

            // Always set this ourself since we need to know it incase we need to login again
            const ssoIdpClaim = account?.idTokenClaims?.idp;
            if (ssoIdpClaim) {
              localStorage.setItem(MSAL_DOMAIN_HINT_KEY, ssoIdpClaim);
            }

            msalInstance.setActiveAccount(account);
          }
          break;
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:
          if (event.error) {
            const error = event.error as AuthError;
            useSpencerStore.getState().actions.setAuthError(error);
            console.error(event);
            // log it to sentry
            captureException(event.error);
          }
          break;
      }
    });

    // If the user signs in from another tab, sync the account
    msalInstance.enableAccountStorageEvents();
    return msalInstance;
  },
  (args) => JSON.stringify(args),
);

export const msalInstance = () => {
  const config = getTenantConfig();
  if (!config) return null;
  return getMsalInstance(config);
};

export const MSAL_DOMAIN_HINT_KEY = 'msal.domain.hint';

// You should never call an MSAL method when another ons is already busy
// Why o why MSAL does not deal with this already I have no idea but the only thing
// we can do is make a hacky work around for it.
// After 24h since last login the refresh token will expire and we will end up in the
// `acquireTokenRedirect` case. Since there are multiple requests in flight you can easily
// end up with race conditions on requests that keep on failing and trying again
const waitForMsalInProgress = () =>
  sharedPromisesCache('msal-in-progress', () =>
    waitFor(() => useSpencerStore.getState().auth.inProgress === InteractionStatus.None),
  );

export const msalLogout = async () => {
  const msal = await msalInstance();
  if (!msal) return null;

  await waitForMsalInProgress();

  // The order in how we clear things here is important!
  // The loading screen is triggered in the useLogOut hook
  return msal.logoutRedirect({
    onRedirectNavigate: (url) => {
      useSpencerStore.persist.clearStorage();
      queryClient.clear();
      localStoragePersister.removeClient();
      if (isNative) return false;
      window.location.href = url;
    },
  });
};

interface GetAccessTokenParams {
  forceRefresh: boolean;
}

export const getAccessToken = (args: GetAccessTokenParams = { forceRefresh: false }) =>
  sharedPromisesCache('access-token', () => getAccessTokenSharedCache(args));

const getAccessTokenSharedCache = async (
  { forceRefresh }: GetAccessTokenParams = { forceRefresh: false },
) => {
  const scopes = getTenantConfig()?.identity?.scopes;
  const msal = await msalInstance();

  if (!msal || !scopes?.length) return null;
  const activeAccount = msal.getActiveAccount();
  if (!activeAccount) return null;

  try {
    await waitForMsalInProgress();

    // Token is not in cache or expired, get it from the server
    const { accessToken } = await msal.acquireTokenSilent({
      scopes,
      account: activeAccount,
      // We need this the first time its requested so we always have a new valid token when the user loads in the page
      // Additionaly this also checks if the ID token is still valid
      // See: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#avoiding-interactive-interruptions-in-the-middle-of-a-users-session
      forceRefresh,
      // acquireTokenSilent only looks at the expiration time of the access token to determine when it needs
      // to refresh the tokens (because it wrongly assumes all tokens have the same lifetime).
      // Microsoft changed their backend and the access token now have a random lifespan between 60 and 90 minutes
      // while the ID token has a fixed 1 hour lifetime (at least by default)
      // This means acquireTokenSilent can return expired ID tokens from cache for at most 30 minutes
      // To work around this, we set the renewal offset to 35 minutes before the accessToken expires
      // which will translate to 5 to 35 minutes before the ID token expires
      // Ref: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
      refreshTokenExpirationOffsetSeconds: 35 * 60,
      // See: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
      redirectUri: `${window.location.origin}/msal-blank.html`,
    });

    return accessToken;
  } catch (error) {
    console.error(error);
    // If we need an interaction, handle it correctly
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      // On boot we might end up here so ensure the user can interact with the page
      // Fetches or domain hint
      const ssoProvider = localStorage.getItem(MSAL_DOMAIN_HINT_KEY) || undefined;

      if (isNative) {
        // Ensures we show back the login screen on native since we dont want to directly
        // open a prompt asking for credentials, thats just odd
        await msalLogout();
      } else {
        await waitForMsalInProgress();
        // On web we can just acquire a token and hope this works correctly
        // Passing in the account and correct redirectUri
        await msal.acquireTokenRedirect({
          scopes,
          domainHint: ssoProvider,
          extraQueryParameters: { ui_locales: getB2cLocale() },
          redirectUri: window.location.origin,
        });
      }

      return null;
    } else {
      // We have an unknown error just logout
      await msalLogout();
    }

    throw error;
  }
};

// This happens in case the user logs in with an account that is allowed by the
// SSO integration but is not know in AD.

const IdentityMismatchRegex = /The account you tried to log in with \((.*)\) can't be found/m;
export const identityMismatchError = (error: AuthError) => {
  try {
    const matches = error.errorMessage.match(IdentityMismatchRegex);
    return matches?.[1];
  } catch {
    return false;
  }
};
