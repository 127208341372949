import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'queries/users/useCurrentUser';

import { measurementsTokenQuery } from './queries';

export const useMeasurementsToken = () => {
  const { user, isLoading } = useCurrentUser();
  const measurementsEnabled = user && 'isTester' in user ? !user.isTester : true;
  const query = useQuery({
    ...measurementsTokenQuery,
    enabled: measurementsEnabled && !isLoading,
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
  };
};
