import { Box, Typography, type TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  backgroundColor?: string;
}

const GooeyTypography = ({ backgroundColor, children, ...props }: Props) => {
  return (
    <Typography mt={2} maxWidth={{ xs: '98%', sm: '95%', md: '95%' }} {...props}>
      <Box
        display="inline"
        pl={{ xs: 2, md: 3 }}
        pr={3}
        py={{ xs: 1, md: 2 }}
        lineHeight={{ xs: '1.7', md: '1.8' }}
        position="relative"
        sx={{
          filter: 'url(#gooey-filter)',
          backgroundColor,
          boxDecorationBreak: 'clone',
          wordBreak: 'break-word',
        }}
      >
        {children}
      </Box>
    </Typography>
  );
};

export default GooeyTypography;
