import { loadingButtonClasses } from '@mui/lab';
import type { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      color: theme.palette.brand.main[100],
      borderRadius: theme.shape.borderRadius * 3,
      [':not(.MuiLoadingButton-loading).Mui-disabled']: {
        backgroundColor: theme.palette.neutral01[400],
      },
      transition: theme.transitions.create(['filter', 'background-color']),
      '&:hover:not(.Mui-disabled)': {
        filter: 'brightness(1.1)',
      },
      '&:active:not(.Mui-disabled)': {
        filter: 'brightness(1.2)',
      },
      ['& .MuiLoadingButton-loadingIndicator']: {
        color: theme.palette.neutral01[100],
      },
    }),
    sizeLarge: ({ theme }) => ({
      padding: theme.spacing(1.5, 3),
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.5',
    }),
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1, 2),
      fontSize: '1rem',
      lineHeight: '1.5',
      fontWeight: 700,
    }),
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(0.5),
      fontSize: '0.8rem',
      lineHeight: '1.25',
      fontWeight: 700,
      '& .MuiButton-iconSizeSmall svg': {
        height: 16,
        width: 16,
      },
    }),
    textSizeSmall: () => ({
      '& .MuiButton-iconSizeSmall svg': {
        height: 16,
        width: 16,
      },
    }),
    text: ({ theme }) => ({
      color: theme.palette.neutral01[800],
    }),
  },
  variants: [
    {
      props: { variant: 'main' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.brand.action.main[100],
        color: theme.palette.brand.action.main.invert,
        '&:hover': {
          backgroundColor: theme.palette.brand.action.main[100],
        },
      }),
    },
    {
      props: { variant: 'outlined', size: 'medium' },
      style: ({ theme }) => ({
        [`& .${loadingButtonClasses.loadingIndicator}`]: {
          color: `${theme.palette.brand.main[100]}`,
        },
      }),
    },
    {
      props: { variant: 'contrast' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.brand.action.contrast[100],
        color: theme.palette.brand.action.contrast.invert,
        '&:hover': {
          backgroundColor: theme.palette.brand.action.contrast[100],
        },
      }),
    },
    {
      props: { variant: 'destructive' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
        },
      }),
    },
  ],
};
