import { Box, Button } from '@mui/material';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMedia } from 'hooks/useMedia';
import { useTenantConfig } from 'queries/tenants/useTenantConfig';

import GooeyTypography from 'components/@common/GooeyTypography';
import Icon from 'components/@icons';

interface Props {
  title: ReactNode;
  content?: ReactNode;
  banner?: ReactNode;
  nextButtonText?: ReactNode;
  onBack?: () => void;
  onNext?: () => void;
}

const OnboardingStep = ({ title, content, banner, nextButtonText, onBack, onNext }: Props) => {
  const { isDesktop } = useMedia();
  const logo = useTenantConfig().config?.assets?.logo?.onboarding;

  if (!isDesktop)
    return (
      <Box
        width="100%"
        height="100vh"
        display="flex"
        flexDirection="column"
        pb="var(--safe-area-inset-bottom, 0px)"
        sx={{
          '@supports (height: 100svh)': {
            height: '100svh',
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={(theme) => theme.palette.brand.main[100]}
        >
          <img src={logo} alt="logo" height="190px" width="190px" />
        </Box>
        <Box
          bgcolor="neutral01.100"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
          pt={3}
          px={2}
          overflow="scroll"
        >
          <Box>
            <GooeyTypography
              variant="h3"
              backgroundColor="brand.title.onboarding.100"
              color="brand.title.onboarding.invert"
            >
              {title}
            </GooeyTypography>
            <Box mt={2}>{content}</Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" flexDirection="column" mt={4} gap={1}>
            {onNext && nextButtonText && (
              <Button fullWidth variant="main" size="large" onClick={onNext}>
                {nextButtonText}
              </Button>
            )}

            {onBack && (
              <Button
                fullWidth
                variant="text"
                size="large"
                startIcon={<Icon name="IcoArrowBack" fontSize={16} />}
                onClick={onBack}
              >
                <FormattedMessage id="core_general_navigate_back" />
              </Button>
            )}
          </Box>
        </Box>

        {!!banner && (
          <Box position="fixed" bottom={0} width="100%" bgcolor="brand.action.contrast.100">
            {banner}
          </Box>
        )}
      </Box>
    );

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="6fr 6fr"
        gap={2}
        flexGrow={1}
        width="100%"
        height="100vh"
        position="fixed"
        sx={{
          '@supports (height: 100svh)': {
            height: '100svh',
          },
        }}
        overflow="scroll"
      >
        <Box pl={18} pr={3} pt={5} pb={2} mb={banner ? 9 : 0}>
          {onBack ? (
            <Box mb={12}>
              <Button
                variant="text"
                size="large"
                startIcon={<Icon name="IcoArrowBack" fontSize={16} />}
                onClick={onBack}
              >
                <FormattedMessage id="core_general_navigate_back" />
              </Button>
            </Box>
          ) : (
            <Box mb={12} height={48} />
          )}

          <Box maxWidth={550}>
            <GooeyTypography
              variant="h3"
              backgroundColor="brand.title.onboarding.100"
              color="brand.title.onboarding.invert"
            >
              {title}
            </GooeyTypography>
            <Box my={6}>{content}</Box>
          </Box>
          {onNext && nextButtonText && (
            <Button variant="main" size="large" onClick={onNext}>
              {nextButtonText}
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" bgcolor="brand.main.100">
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: '100%', objectFit: 'contain' }}
            height="400px"
            width="400px"
          />
        </Box>
      </Box>

      {banner && (
        <Box position="fixed" bottom={0} width="100%" bgcolor="brand.action.contrast.100">
          {banner}
        </Box>
      )}
    </>
  );
};

export default OnboardingStep;
