import { useMemo } from 'react';

import { DEFAULT_LOCALE } from 'utils/env.utils';

import { useTenantConfig } from './useTenantConfig';

export const useLocales = () => {
  const locales = useTenantConfig()?.config?.locales;

  return useMemo(
    () => ({
      locales: locales?.available ?? [],
      defaultLocale: locales?.default ?? DEFAULT_LOCALE,
    }),
    [locales],
  );
};
