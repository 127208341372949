import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { useMutation } from '@tanstack/react-query';

export const useManualUpdate = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      const latestBundle = await CapacitorUpdater.getLatest();
      const listDownloadedBundles = await CapacitorUpdater.list();

      const matchedBundle = listDownloadedBundles.bundles.find(
        (bundle) => bundle.version === latestBundle.version,
      );

      if (matchedBundle) {
        await CapacitorUpdater.set(matchedBundle);
      } else if (latestBundle.url) {
        const bundleInfo = await CapacitorUpdater.download(latestBundle);
        await CapacitorUpdater.set(bundleInfo);
      }
    },
  });

  return {
    isLoading: mutation.isPending,
    applyUpdate: mutation.mutate,
    isError: mutation.isError,
  };
};
