import type { Components, Theme } from '@mui/material';

export const MuiBottomNavigationAction: Components<Theme>['MuiBottomNavigationAction'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral01[500],
      height: '100%',
    }),
    label: ({ theme }) => ({
      ...theme.typography.caption,
      '&.Mui-selected': {
        ...theme.typography.caption,
      },
    }),
  },
};
