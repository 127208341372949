import { useQuery } from '@tanstack/react-query';

import { enabledModulesQuery } from './queries';

export const useEnabledModules = () => {
  const query = useQuery({
    ...enabledModulesQuery,
  });

  return {
    data: query?.data?.enabledModules ?? [],
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
  };
};
