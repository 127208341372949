import { useNewsTopics } from 'queries/topics/useNewsTopics';
import { useUserTopicIds } from 'queries/topics/useUserTopicIds';

export const useSubscriptionFormTopics = () => {
  const { data: newsTopics, isLoading: isLoadingNewsTopics } = useNewsTopics();
  const { data: userTopicIds, isLoading: isLoadingUserTopicIds } = useUserTopicIds();

  const isLoading = isLoadingNewsTopics || isLoadingUserTopicIds;

  const mandatoryTopics = newsTopics.filter((topic) => !topic.canUnsubscribe);
  const otherTopics = newsTopics.filter((topic) => topic.canUnsubscribe);

  return {
    isLoading,
    mandatoryTopics,
    otherTopics,
    userTopicIds,
    newsTopics,
  };
};
