import { Helmet, HelmetData } from 'react-helmet-async';

import { useTenantConfig } from 'queries/tenants/useTenantConfig';

const helmetData = new HelmetData({});

const DefaultSeo = () => {
  const { config } = useTenantConfig();
  const favicon = config?.assets.favicon;
  return (
    <Helmet helmetData={helmetData}>
      <title>{config?.name}</title>
      {favicon && <link rel="icon" type="image/png" href={favicon} />}
    </Helmet>
  );
};

export default DefaultSeo;
