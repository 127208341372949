import { type AuthError, InteractionStatus } from '@azure/msal-browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { DEFAULT_LOCALE, SPENCER_STORE_LOCAL_STORAGE_KEY } from 'utils/env.utils';

type SpencerState = {
  locale: string;
  showCookieBanner: boolean;
  endpointArn: string;
  recentSearches: Array<string>;
  loadingState: {
    showLoadingSpinner: boolean;
    showSplashScreen: boolean;
  };
  auth: {
    inProgress: InteractionStatus;
    error: AuthError | null;
  };
  authInProgress: InteractionStatus;
  keyboardActive: boolean;
};

type SpencerActions = {
  addRecentSearch: (search: string) => void;
  resetRecentSearches: () => void;
  setCookieBanner: (toggle: boolean) => void;
  setLocale: (locale: string) => void;
  setEndpointArn: (endpointArn: string) => void;
  setLoadingState: (loadingState: { spinner: boolean; splashScreen: boolean }) => void;
  setKeyboardActive: (active: boolean) => void;
  setAuthInProgress: (inProgress: InteractionStatus) => void;
  setAuthError: (error: AuthError | null) => void;
};

type SpencerStore = SpencerState & { actions: SpencerActions };

export const useSpencerStore = create<SpencerStore>()(
  immer(
    persist(
      subscribeWithSelector(
        devtools(
          (set) => ({
            locale: DEFAULT_LOCALE,
            showCookieBanner: true,
            endpointArn: '',
            recentSearches: [],
            loadingState: {
              showLoadingSpinner: true,
              showSplashScreen: true,
            },
            auth: {
              inProgress: InteractionStatus.None,
              error: null,
            },
            actions: {
              addRecentSearch: (search) =>
                set(
                  (state) => {
                    const uniqueSearches = [...new Set([search, ...state.recentSearches])];
                    if (state.recentSearches.length >= 5) {
                      uniqueSearches.pop();
                    }
                    state.recentSearches = uniqueSearches;
                  },
                  false,
                  { type: 'addRecentSearch' },
                ),
              resetRecentSearches: () =>
                set(
                  (state) => {
                    state.recentSearches = [];
                  },
                  false,
                  { type: 'resetRecentSearches' },
                ),

              setCookieBanner: (toggle) =>
                set(
                  (state) => {
                    state.showCookieBanner = toggle;
                  },
                  false,
                  { type: 'setCookieBanner' },
                ),

              setLocale: (locale) =>
                set(
                  (state) => {
                    state.locale = locale;
                  },
                  false,
                  { type: 'setLocale' },
                ),

              setLoadingState: ({ spinner, splashScreen }) =>
                set(
                  (state) => {
                    state.loadingState.showLoadingSpinner = spinner;
                    state.loadingState.showSplashScreen = splashScreen;
                  },
                  false,
                  { type: 'setLoadingState' },
                ),

              setEndpointArn: (endpointArn) =>
                set(
                  (state) => {
                    state.endpointArn = endpointArn;
                  },
                  false,
                  { type: 'setEndpointArn' },
                ),
              setKeyboardActive: (active) =>
                set(
                  (state) => {
                    state.keyboardActive = active;
                  },
                  false,
                  { type: 'setKeyboardActive' },
                ),

              setAuthInProgress: (inProgress) =>
                set(
                  (state) => {
                    state.auth.inProgress = inProgress;
                  },
                  false,
                  { type: 'setAuthInProgress' },
                ),

              setAuthError: (authError) =>
                set(
                  (state) => {
                    state.auth.error = authError;
                  },
                  false,
                  { type: 'setAuthError' },
                ),
            },
          }),
          { serialize: true, store: 'sp' },
        ),
      ),
      {
        name: SPENCER_STORE_LOCAL_STORAGE_KEY,
        version: 1,
        partialize: (state) => ({
          locale: state.locale,
          showCookieBanner: state.showCookieBanner,
          endpointArn: state.endpointArn,
          recentSearches: state.recentSearches,
        }),
      },
    ),
  ),
);

useSpencerStore.subscribe(
  (state) => state.loadingState,
  ({ showSplashScreen }, _prevState) => {
    if (showSplashScreen) {
      SplashScreen.show({ autoHide: false });
    } else {
      SplashScreen.hide({ fadeOutDuration: 200 });
    }
  },
);
