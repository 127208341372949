import { useMediaQuery } from '@mui/material';

/**
 * TODO(js): This should not be used, rather as much as possible we want to utilize the breakpoints
 * options provided by Material UI.
 * If you really need to use it, use it directly inside the component
 */
export const useMedia = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
};
